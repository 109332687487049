import React, { useState } from 'react';
import { DesignPageHeader } from '../components/design_page_header';
import { Input } from '../../../../components/inputs_and_selections/input';
import { getFluidProperties } from '../../../../code/models/fluids';
import { tryParseFloat } from '../../../../code/helpers';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { sum } from 'lodash';
import { INDEX_PIPE_LENGTH_HELPER_TEXT, IndexCircuitInfo, IndexEmitterFormLabel, IndexEmitterSelect, PipeworkInputs, PRIMARY_PIPE_LENGTH_HELPER_TEXT, PrimaryPipeFormLabel, SECONDARY_PIPE_LENGTH_HELPER_TEXT, SecondaryEmittersCard, SecondaryEmittersSelectPage, SecondaryPipeFormLabel } from '../../survey/pipework';
import { Icon } from '../../../../components/buttons/icon';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { Info } from '../../../../components/buttons/info';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { numberFormat } from '../../../../code/number_format';
export const PipeworkDesignPage = ({ setDesignTab, flowTempC, deltaTFlowReturnC, design, setDesign, survey, pipeDataList, designedEmitters, indexEmitterUUID, secondaryEmitterUUIDS, secondaryEmitterUUIDsThroughReplacement }) => {
    var _a, _b, _c, _d, _e;
    const [selectedPipeMaterial, setSelectedPipeMaterial] = useState();
    const [selectedPipeSection, setSelectedPipeSection] = useState();
    const [page, setPage] = useState();
    const { temperature_c, density_kg_per_m3, specific_heat_capacity_j_per_kg_k, kinematic_viscosity_m_per_s } = getFluidProperties('WATER', flowTempC, deltaTFlowReturnC);
    const emittersOnSecondaryLength = designedEmitters.filter(x => secondaryEmitterUUIDS.includes(x.uuid)).length;
    // Extract the different lines of pipe data for clarity and to avoid repetition
    const primaryPipeData = pipeDataList.find(x => x.id === 'PRIMARY');
    const secondaryPipeData = pipeDataList.find(x => x.id === 'SECONDARY');
    const indexEmitterPipeData = pipeDataList.find(x => x.id === 'INDEX');
    const indexEmitter = designedEmitters.find(x => x.uuid === indexEmitterUUID);
    const secondaryEmitters = designedEmitters.filter(x => secondaryEmitterUUIDS.includes(x.uuid));
    // We keep 3 seperate lists here: design secondary index emitter uuids, survey secondary index emitter uuids and removed secondary index emitter uuids.
    // If we know the rad hasn't been replaced and isn't an added design radiator work from removed array, otherwise work from design radiators array.
    const toggleEmitter = (emitter) => {
        // if the emitter is on the list of survey index emitters then toggle it on and off that list
        if (emitter.type !== 'DESIGN' && survey.secondary_index_emitter_uuids.some(y => y === emitter.uuid)) {
            const updatedDesign = { ...design };
            // if it is also on the list of design index emitters (because it was added belatedly to the list of survey emitters) then remove it from the design list as that gets very confusing
            if (updatedDesign.secondary_index_emitter_uuids.some(y => y === emitter.uuid)) {
                updatedDesign.secondary_index_emitter_uuids = updatedDesign.secondary_index_emitter_uuids.filter(y => y !== emitter.uuid);
            }
            // Either way toggle it on and off the "removed from survey list"
            updatedDesign.removed_secondary_index_emitter_uuids = updatedDesign.removed_secondary_index_emitter_uuids.some(y => y === emitter.uuid)
                ? updatedDesign.removed_secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // add back in
                : [...updatedDesign.removed_secondary_index_emitter_uuids, emitter.uuid]; // otherwise remove
            setDesign(updatedDesign);
        }
        else {
            // if the emitter is here because it replaced a survey emitter that was on the secondary then then toggle it on and off the removed list
            if (secondaryEmitterUUIDsThroughReplacement.includes(emitter.uuid)) {
                setDesign(({
                    ...design,
                    removed_secondary_index_emitter_uuids: design.removed_secondary_index_emitter_uuids.some(y => y === emitter.uuid)
                        ? design.removed_secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // add back in
                        : [...design.removed_secondary_index_emitter_uuids, emitter.uuid] // otherwise remove
                }));
            }
            else {
                // otherwise add/remove it from the design list (even if it's a survey emitter - the index circuit may just have changed)
                setDesign(({
                    ...design,
                    secondary_index_emitter_uuids: design.secondary_index_emitter_uuids.some(y => y === emitter.uuid) // if design rad that is already in the list
                        ? design.secondary_index_emitter_uuids.filter(y => y !== emitter.uuid) // remove it
                        : [...design.secondary_index_emitter_uuids, emitter.uuid] // otherwise add in
                }));
            }
        }
    };
    if (page === 'SECONDARY_EMITTERS') {
        return React.createElement(SecondaryEmittersSelectPage, { onBack: () => setPage('MAIN'), emitterUUIDs: secondaryEmitterUUIDS, toggleEmitter: toggleEmitter, allEmitters: designedEmitters });
    }
    // Detailed pages for each pipe
    if (selectedPipeSection === 'PRIMARY' && primaryPipeData) {
        return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
            React.createElement(DesignPageHeader, { title: 'Pipework', onBack: () => setSelectedPipeSection(undefined) }),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex overflow-y-auto" },
                React.createElement(PrimaryPipeFormLabel, null),
                React.createElement(PipeworkInputs, { pipeMaterial: selectedPipeMaterial, setPipeMaterial: setSelectedPipeMaterial, pipeModelUUID: (_a = primaryPipeData === null || primaryPipeData === void 0 ? void 0 : primaryPipeData.pipeMaterial) === null || _a === void 0 ? void 0 : _a.uuid, setPipeModel: (e) => setDesign({ ...design, primary_pipework_uuid_override: e }), pipeLengthHelperText: PRIMARY_PIPE_LENGTH_HELPER_TEXT, pipeworkLengthM: primaryPipeData.lengthM, setPipeworkLength: (e) => setDesign({ ...design, primary_pipework_length_m_override: e }), lengthOverridden: design.primary_pipework_length_m_override !== undefined && survey.primary_pipework_length_m !== undefined, clearOverride: () => setDesign({ ...design, primary_pipework_length_m_override: undefined }) }),
                React.createElement("div", { className: "h-0 border border-gray-200" }),
                React.createElement(CalculationSection, { pipeData: primaryPipeData })));
    }
    if (selectedPipeSection === 'SECONDARY' && secondaryPipeData) {
        return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
            React.createElement(DesignPageHeader, { title: 'Pipework', onBack: () => setSelectedPipeSection(undefined) }),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex overflow-y-auto" },
                React.createElement(SecondaryPipeFormLabel, null),
                React.createElement(SecondaryEmittersCard, { setCurrentPage: setPage, emittersOnCircuitLength: emittersOnSecondaryLength, numberOfEmitters: designedEmitters.length }),
                React.createElement(PipeworkInputs, { pipeMaterial: selectedPipeMaterial, setPipeMaterial: setSelectedPipeMaterial, pipeModelUUID: (_b = secondaryPipeData === null || secondaryPipeData === void 0 ? void 0 : secondaryPipeData.pipeMaterial) === null || _b === void 0 ? void 0 : _b.uuid, setPipeModel: (e) => setDesign({ ...design, secondary_pipework_uuid_override: e }), pipeLengthHelperText: SECONDARY_PIPE_LENGTH_HELPER_TEXT, pipeworkLengthM: secondaryPipeData.lengthM, setPipeworkLength: (e) => setDesign({ ...design, secondary_pipework_length_m_override: e }), lengthOverridden: design.secondary_pipework_length_m_override !== undefined && survey.secondary_index_pipework_length_m !== undefined, clearOverride: () => setDesign({ ...design, secondary_pipework_length_m_override: undefined }) }),
                React.createElement("div", { className: "h-0 border border-gray-200" }),
                React.createElement(CalculationSection, { pipeData: secondaryPipeData })));
    }
    if (selectedPipeSection === 'INDEX' && indexEmitterPipeData) {
        return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
            React.createElement(DesignPageHeader, { title: 'Pipework', onBack: () => setSelectedPipeSection(undefined) }),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex overflow-y-auto" },
                React.createElement(IndexEmitterFormLabel, null),
                React.createElement(IndexEmitterSelect, { indexEmitterUuid: indexEmitterUUID, setIndexEmitterUuid: (e) => setDesign({ ...design, index_emitter_uuid_override: e }), possibleIndexEmittersWithRooms: secondaryEmitterUUIDS.length > 0
                        ? secondaryEmitters
                        : designedEmitters }),
                indexEmitter && React.createElement(PipeworkInputs, { pipeMaterial: selectedPipeMaterial, setPipeMaterial: setSelectedPipeMaterial, pipeModelUUID: (_c = indexEmitterPipeData === null || indexEmitterPipeData === void 0 ? void 0 : indexEmitterPipeData.pipeMaterial) === null || _c === void 0 ? void 0 : _c.uuid, setPipeModel: (e) => setDesign({ ...design, index_pipework_uuid_override: e }), pipeLengthHelperText: INDEX_PIPE_LENGTH_HELPER_TEXT, pipeworkLengthM: indexEmitterPipeData.lengthM, setPipeworkLength: (e) => setDesign({ ...design, index_pipework_length_m_override: e }), editPipeModel: indexEmitter.type !== 'UNDERFLOOR', lengthOverridden: design.index_pipework_length_m_override !== undefined && survey.index_emitter_pipe_length_m !== undefined, clearOverride: () => setDesign({ ...design, index_pipework_length_m_override: undefined }) }),
                React.createElement("div", { className: "h-0 border border-gray-200" }),
                React.createElement(CalculationSection, { pipeData: indexEmitterPipeData })));
    }
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { title: 'Pipework', onBack: () => setDesignTab(undefined) }),
        React.createElement("div", { className: "p-5 bg-white flex-col gap-6 flex overflow-y-auto" },
            React.createElement(FormLabel, { labelText: 'Index circuit', info: React.createElement(IndexCircuitInfo, null), size: 'XL' }),
            React.createElement("div", { className: "flex-col flex" },
                React.createElement(FormLabel, { labelText: 'Sections', size: 'LG' }),
                React.createElement("div", { className: 'flex flex-col divide-y divide-gray-200' },
                    primaryPipeData && React.createElement(PipeSectionCard, { pipeData: primaryPipeData, onClick: () => {
                            var _a, _b;
                            setSelectedPipeSection('PRIMARY');
                            setSelectedPipeMaterial((_b = (_a = primaryPipeData === null || primaryPipeData === void 0 ? void 0 : primaryPipeData.pipeMaterial) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : 'Copper');
                        } }),
                    secondaryPipeData && React.createElement(PipeSectionCard, { pipeData: secondaryPipeData, onClick: () => {
                            var _a, _b;
                            setSelectedPipeSection('SECONDARY');
                            setSelectedPipeMaterial((_b = (_a = secondaryPipeData === null || secondaryPipeData === void 0 ? void 0 : secondaryPipeData.pipeMaterial) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : 'Copper');
                        } }),
                    indexEmitterPipeData && React.createElement(PipeSectionCard, { pipeData: indexEmitterPipeData, onClick: () => {
                            var _a, _b;
                            setSelectedPipeSection('INDEX');
                            setSelectedPipeMaterial((_b = (_a = indexEmitterPipeData === null || indexEmitterPipeData === void 0 ? void 0 : indexEmitterPipeData.pipeMaterial) === null || _a === void 0 ? void 0 : _a.material) !== null && _b !== void 0 ? _b : 'Copper');
                        } }))),
            React.createElement("div", { className: 'flex flex-col gap-3' },
                React.createElement(FormLabel, { labelText: 'Totals', size: 'LG' }),
                React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Allowance for emitters and fittings', helperText: 'Enter the uplift you want to use to account for emitters and fittings on the circuit. CIBSE suggest an allowance of 33 to 50%' }), input: React.createElement(Input, { value: (_d = design.allowance_for_emitters_and_fittings) !== null && _d !== void 0 ? _d : '', setValue: (e) => setDesign({ ...design, allowance_for_emitters_and_fittings: tryParseFloat(e, undefined) }), postfix: '%' }) }),
                React.createElement("div", { className: 'flex flex-col gap-3' },
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between items-center gap-3 flex" },
                        React.createElement("div", { className: "text-sm font-bold" }, "Total pipes only"),
                        React.createElement("div", { className: "text-sm font-bold" },
                            numberFormat(1).format(sum(pipeDataList.map(x => x.totalPressureDropkPa))),
                            " kPa")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between items-center gap-3 flex" },
                        React.createElement("div", { className: "items-center gap-1 flex" },
                            React.createElement("div", { className: "text-sm font-bold" }, "Total including fittings and emitters (approximate)"),
                            React.createElement(Info, { infoModalHeader: 'What\'s included in this pressure drop?', infoModalBody: 'This estimate includes the pressure drop through the straight pipework, plus an allowance for the drop through the fittings and emitters. It doesn\'t include the pressure drop through the heat pump\'s heat exchanger.' })),
                        React.createElement("div", { className: "text-sm font-bold" },
                            numberFormat(1).format(sum(pipeDataList.map(x => x.totalPressureDropkPa)) * (1 + (((_e = design.allowance_for_emitters_and_fittings) !== null && _e !== void 0 ? _e : 0) / 100))),
                            " kPa")))),
            React.createElement("div", { className: "h-0 border border-gray-200" }),
            React.createElement(FluidPropertiesSection, { temperature_c: temperature_c, density_kg_per_m3: density_kg_per_m3, specific_heat_capacity_j_per_kg_k: specific_heat_capacity_j_per_kg_k, kinematic_viscosity_m_per_s: kinematic_viscosity_m_per_s })));
};
export const PipeSectionCard = ({ pipeData, onClick }) => {
    return React.createElement("div", { key: pipeData.name, className: "py-4 justify-between items-center gap-4 flex cursor-pointer", onClick: onClick },
        React.createElement("div", { className: "flex-col flex" },
            React.createElement(FormLabel, { labelText: pipeData.name }),
            React.createElement("div", { className: "text-gray-500 text-sm" },
                pipeData.kwatts,
                " kW \u2022 ",
                React.createElement("div", { className: "inline-block" }, pipeData.velocityAlert
                    ? React.createElement(Badge, { color: 'RED', text: pipeData.velocityMPerS + ' m/s' })
                    : React.createElement("div", null,
                        pipeData.velocityMPerS,
                        " m/s")),
                "\u2022 ",
                React.createElement("div", { className: "inline-block" }, pipeData.linearPressureDropAlert
                    ? React.createElement(Badge, { color: 'YELLOW', text: pipeData.pressureDropPaPerM + ' Pa/m' })
                    : React.createElement("div", null,
                        pipeData.pressureDropPaPerM,
                        " Pa/m")),
                "\u2022 ",
                pipeData.totalPressureDropkPa,
                " kPa")),
        onClick && React.createElement(Icon, { icon: faChevronRight }));
};
const CalculationSection = ({ pipeData }) => {
    const attributes = [
        { key: 'Heat demand', value: `${pipeData.kwatts} kW` },
        { key: 'Flow rate', value: `${((pipeData.flowRateM3PerS * 1000) * 60).toFixed(1)} l/min` },
        {
            key: 'Velocity',
            value: `${pipeData.velocityMPerS} m/s`,
            badge_color: pipeData.velocityAlert ? 'RED' : undefined
        },
        {
            key: 'Pressure drop per unit',
            value: `${pipeData.pressureDropPaPerM} Pa/m`,
            badge_color: pipeData.linearPressureDropAlert ? 'YELLOW' : undefined
        },
        { key: 'Pipe pressure drop', value: `${numberFormat(1).format(pipeData.totalPressureDropkPa)} kPa` }
    ];
    return React.createElement("div", { className: "flex-col gap-3 flex" },
        React.createElement("div", { className: "text-gray-900 font-bold" }, "Calculations"),
        React.createElement("div", { className: 'flex-col flex' },
            pipeData.velocityAlert && React.createElement(Alert, { type: 'DANGER' },
                React.createElement("div", { className: "items-center gap-1 flex" },
                    'Pipe velocity exceeds 1.5m/s',
                    React.createElement(Info, { infoModalHeader: 'Pipe velocity exceeds 1.5m/s', infoModalBody: 'This will result in excessive noise and the frictional resistance to flow will be significant. Increase the pipe diameter to reduce the flow velocity.', colour: 'text-red-800' }))),
            pipeData.linearPressureDropAlert && React.createElement(Alert, { type: 'WARNING' },
                React.createElement("div", { className: "items-center gap-1 flex" },
                    'Pipe pressure drop exceeds 300Pa/m',
                    React.createElement(Info, { infoModalHeader: 'Pipe pressure drop exceeds 300Pa/m', infoModalBody: 'This may be ok in certain circumstances, such as when the pipe is very short, but you should check the pump sizing carefully if you want to stick with this pipe size. Alternatively, increase the pipe diameter to reduce the linear pressure drop.', colour: 'text-yellow-800' })))),
        React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, attributes.map(x => React.createElement("div", { key: x.key, className: "py-2 justify-between gap-2 flex" },
            React.createElement("div", { className: "text-sm" }, x.key),
            x.badge_color
                ? React.createElement(Badge, { color: x.badge_color, text: x.value })
                : React.createElement("div", { className: "text-gray-900 text-sm font-semibold" }, x.value)))));
};
const FluidPropertiesSection = ({ temperature_c, density_kg_per_m3, specific_heat_capacity_j_per_kg_k, kinematic_viscosity_m_per_s }) => {
    return React.createElement("div", { className: "flex-col gap-6 flex" },
        React.createElement(FormLabel, { labelText: 'Fluid Properties', helperText: 'This section shows the fluid properties we have used in the calculations above', size: 'XL', info: React.createElement(Info, { infoModalHeader: 'Fluid Properties', infoModalBody: 'The properties of the fluid in the heating system impact how much heat the system can carry and the pressure drop through the system. ' +
                    'We are assuming you are just using water in the system, as anti-freeze additives like glycol significantly reduce the heat carrying capacity of the system.' }) }),
        React.createElement("div", { className: "flex-col flex" },
            React.createElement("div", { className: "pb-2 justify-between gap-2 flex" },
                React.createElement("div", { className: "text-sm" }, "Fluid"),
                React.createElement("div", { className: "text-right text-sm" }, "Water")),
            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                React.createElement("div", { className: "text-sm" }, "Average flow temperature"),
                React.createElement("div", { className: "text-right text-sm" },
                    temperature_c,
                    " \u00B0C")),
            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                React.createElement("div", { className: "text-sm" }, "Density"),
                React.createElement("div", { className: "text-right text-sm" },
                    density_kg_per_m3.toFixed(0),
                    " kg/m\u00B3")),
            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                React.createElement("div", { className: "text-sm" }, "Specific heat capacity"),
                React.createElement("div", { className: "text-right text-sm" },
                    specific_heat_capacity_j_per_kg_k.toFixed(0),
                    " J/kg\u00B7K")),
            React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                React.createElement("div", { className: "text-sm" }, "Kinematic viscosity"),
                React.createElement("div", { className: "text-right text-sm" },
                    kinematic_viscosity_m_per_s.toExponential(2),
                    " m/s"))));
};
