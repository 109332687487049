import React from 'react';
import { DesignPageHeader } from '../design/components/design_page_header';
import { CompleteButton } from './complete_button';
import { FloorplanHeatLossSettingsBlock } from './floorplan/floorplan_heatloss_settings_block';
export const SurveySettings = ({ setSurveyTab, survey, setSurvey, designTempDefault, altitudeDefaultM }) => {
    const page = 'SETTINGS';
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { title: 'Settings', onBack: () => setSurveyTab(''), completed: survey.completed_sections.includes(page) }),
        React.createElement("div", { className: "p-5 flex flex-col gap-6 overflow-y-auto flex-grow" },
            React.createElement(FloorplanHeatLossSettingsBlock, { survey: survey, setSurvey: setSurvey, designTempDefault: designTempDefault, altitudeDefaultM: altitudeDefaultM })),
        React.createElement(CompleteButton, { page: page, survey: survey, setSurvey: setSurvey, pageChecks: [], onBack: async () => setSurveyTab('') }));
};
