import React from 'react';
import { FlowWrapperContext } from '../components/survey_section_flow_wrapper';
import { SurveyElectricsContext } from '../electrics';
import { PhotoScroll } from '../../../../components/inputs_and_selections/photo_scroll';
export const ElectricsIncomingMainAndFusePhotosBlock = () => {
    const electricsContext = React.useContext(SurveyElectricsContext);
    const flowContext = React.useContext(FlowWrapperContext);
    const { survey, setSurvey, files, setFiles, companyUUID } = electricsContext;
    const fuseImages = electricsContext.survey.electrics_images.map(x => files.find(y => y.uuid === x.image_uuid));
    const deleteImage = (imageUUID) => {
        setSurvey({ ...survey, electrics_images: survey.electrics_images.filter(x => x.image_uuid !== imageUUID) });
        setFiles(prev => prev.map(x => x.uuid === imageUUID ? { ...x, deleted_at: new Date().getTime(), updated_at: new Date().getTime() } : x));
    };
    const isFullScreen = fuseImages.length === 0 && flowContext.viewMode === 'FLOW';
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: isFullScreen ? 'p-5 h-full bg-gray-50 flex flex-col items-center justify-center gap-4' : 'flex flex-col gap-4' },
            React.createElement("div", { className: `${isFullScreen ? 'items-center' : ''} flex flex-col gap-1` },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Incoming main and fuse"),
                React.createElement("div", { className: `${isFullScreen ? 'text-center' : ''} text-gray-500 text-sm` })),
            React.createElement("div", { className: "flex-col gap-3 flex" },
                !isFullScreen && React.createElement("div", { className: "text-gray-900 font-bold" }, "Photos"),
                React.createElement(PhotoScroll, { viewMode: isFullScreen ? 'COMPACT' : 'NORMAL', images: fuseImages, addImage: (fileWrapper) => {
                        fileWrapper && setFiles(prev => [...prev, { ...fileWrapper, updated_at: new Date().getTime(), created_at: new Date().getTime() }]);
                        fileWrapper && setSurvey({
                            ...survey,
                            electrics_images: [...survey.electrics_images, {
                                    uuid: crypto.randomUUID(),
                                    entity_uuid: 'survey',
                                    entity_attribute: 'electrics_images',
                                    image_uuid: fileWrapper.uuid
                                }]
                        });
                    }, deleteImage: deleteImage, companyUUID: companyUUID }))));
};
