import * as _ from 'lodash';
import { sum } from 'lodash';
import { HeatPumpSchema } from './models/heat_pump';
import { HotWaterCylinderSchema } from './models/hot_water_cylinder';
import { CAVITY_WALL, CAVITY_WALL_INSULATION, FLOOR_INSULATION, LOFT_INSULATION, SOLID_WALL_INSULATION, TIMBER, WALL_GROUPS, WINDOW_TYPES } from './models/u_value';
import { yearDiff } from './time_since';
import { getDesignConditions } from './models/design_temp';
import { checkIfScottish } from './models/address';
import { FUELS, getFuelByName } from './models/fuel';
import { makeDictFromScottishImprovements } from './helpers';
import { getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from './models/range_heat_pump';
import { convertLatLngListToLatLongLiteral } from './geocoding';
import { PROPERTY_TYPES } from './models/property_type';
import { BUILT_FORM_TYPES } from './models/built_form';
import { z } from 'zod';
import { roundTo2dp } from './number_format';
const GrantEligibleReasonSchema = z.object({
    id: z.string(),
    hasPassed: z.boolean(),
    warning: z.boolean(),
    message: z.string()
});
const DefaultsSchema = z.object({
    externalWallDefault: z.number(),
    partyWallDefault: z.number(),
    windowDefault: z.number(),
    floorDefault: z.number(),
    roofDefault: z.number(),
    airChangesDefault: z.number(),
    internalTempDefault: z.number(),
    designTempDefault: z.number()
});
export const EstimateSchema = z.object({
    hotWaterCylinder: HotWaterCylinderSchema.optional(),
    heatPump: HeatPumpSchema.optional(),
    labour: z.number(),
    survey: z.number(),
    grant: z.number(),
    designTempC: z.number(),
    internalTempC: z.number(),
    externalWallUValue: z.number(),
    partyWallUValue: z.number(),
    windowsUValue: z.number(),
    floorUValue: z.number(),
    roofUValue: z.number(),
    partyWallWatts: z.number(),
    externalWallWatts: z.number(),
    windowWatts: z.number(),
    floorWatts: z.number(),
    roofWatts: z.number(),
    ventilationWatts: z.number(),
    dayRate: z.number(),
    days: z.number(),
    totalWatts: z.number(),
    sCOP: z.number(),
    flowTempC: z.number(),
    CO2SavedKg: z.number(),
    flightsSaved: z.number(),
    commutesSaved: z.number(),
    airChanges: z.number(),
    yearlyCostCurrentGBP: z.number(),
    yearlyCostHeatPumpGBP: z.number(),
    busGrantEligibleReasons: z.array(GrantEligibleReasonSchema),
    hesGrantEligibleReasons: z.array(GrantEligibleReasonSchema),
    defaults: DefaultsSchema,
    totalLineItems: z.number(),
    totalPrice: z.number(),
    isScottish: z.boolean()
});
// Useful to export default values of constants we use in our estimates.
export const DEFAULT_FLOW_TEMP_C = 45;
export const DEFAULT_INTERNAL_TEMP_C = 20;
export const DEFAULT_AIR_CHANGES = 1;
export const roofUValueFromInsulationThickness = (loftInsulationThickness) => {
    if (!loftInsulationThickness)
        return 0;
    // for back compatability if value is 300mm use value for 250+mm
    const thicknessUUID = loftInsulationThickness === '300mm' ? '250+mm' : loftInsulationThickness;
    const type = LOFT_INSULATION.find(x => x.uuid === thicknessUUID);
    if (!type)
        return 0;
    return type.u_value;
};
export const windowUValueFromType = (windowType) => {
    const type = WINDOW_TYPES.find(x => x.uuid === windowType);
    if (!type)
        return 0;
    return type.u_value;
};
export const floorUValueFromType = (floorType) => {
    const type = FLOOR_INSULATION.find(x => x.uuid === floorType);
    if (!type)
        return 0;
    return type.u_value;
};
export const calculateEstimate = (lead, heatPumps, hotWaterCylinders, company, selectedHeatPumpUUID, selectedHotWaterCylinderUUID) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { designTempC, internalTempC, externalWallUValue, partyWallUValue, windowsUValue, floorUValue, roofUValue, partyWallWatts, externalWallWatts, windowWatts, floorWatts, roofWatts, ventilationWatts, totalWatts, airChanges, htcWPerK, defaults, degreeDays } = calculatePeakHeatLossW(lead, company);
    const flowTempC = (_b = (_a = lead.flow_temperature_c) !== null && _a !== void 0 ? _a : company === null || company === void 0 ? void 0 : company.default_flow_temp_c) !== null && _b !== void 0 ? _b : DEFAULT_FLOW_TEMP_C;
    const numberOfBedrooms = getNumberOfBedrooms(lead);
    // const noBathroomsOverride = lead.property.houseOverrides?.noBathrooms ? lead.property.houseOverrides.noBathrooms : lead.property.noBathrooms
    const yearlySpacekWhHeat = htcWPerK * degreeDays / (1000 / 24);
    const yearlyWaterkWhHeat = 365 * (3 * (numberOfBedrooms + 1)); // Assume occupants = bedrooms + 1. Assume 3kwh a day per person for hot water, taken from MCS standards.
    const yearlykWhHeat = yearlySpacekWhHeat + yearlyWaterkWhHeat;
    // If the fuel type is overridden, use that, otherwise use the fuel type entered by the homeowner in the enquiry form
    const currentFuelType = (_d = (_c = lead.property.houseOverrides) === null || _c === void 0 ? void 0 : _c.fuelType) !== null && _d !== void 0 ? _d : lead.property.fuelType;
    const currentFuel = getFuelByName(currentFuelType);
    const yearlykWhCurrentFuel = yearlykWhHeat / currentFuel.defaultHeatingSystemEfficiency;
    const yearlyCO2CurrentKg = calculateYearlyCO2kg(currentFuel, yearlykWhCurrentFuel);
    const yearlyCostCurrentGBP = calculateYearlyCostGBP(currentFuel, yearlykWhCurrentFuel);
    // Below only used in estimate currently, so can hard code the flow temp at 45C for now
    const heatPump = selectHeatPump(totalWatts, heatPumps, selectedHeatPumpUUID, lead.heat_pump_uuid, company, designTempC, flowTempC);
    const hotWaterCylinder = selectCylinder(numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, lead.hot_water_cylinder_uuid);
    // If no range heat pump then default to 3.6, which is the sCOP for
    // the Vaillant Arotherm Plus 7kW, 50C flow. https://energy-stats.uk/how-to-measure-vaillant-arotherm-cop/
    // If we have a range heat pump but return a sCOP of 0, which happens if we don't have data the specified flow temp, stick with 0
    // Will mean we end up with negative infinity carbon savings, but is "correct" in the sense the user has specified a flow temp the heat pump can't do
    const sCOP = (heatPump === null || heatPump === void 0 ? void 0 : heatPump.range_heat_pump) ? getHeatPumpScopAtFlowTemp(heatPump === null || heatPump === void 0 ? void 0 : heatPump.range_heat_pump, flowTempC) : 3.6;
    const electricity = FUELS.find(x => x.name === 'Electric');
    const yearlykWhHeatPump = yearlykWhHeat / sCOP;
    const yearlyCO2HeatPumpKg = calculateYearlyCO2kg(electricity, yearlykWhHeatPump);
    const yearlyCostHeatPumpGBP = calculateYearlyCostGBP(electricity, yearlykWhHeatPump);
    const CO2SavedKg = Math.round(yearlyCO2CurrentKg - yearlyCO2HeatPumpKg);
    const flightsSaved = getFlightsSaved(CO2SavedKg);
    const commutesSaved = getCommutesSaved(CO2SavedKg);
    const dayRate = (_e = company === null || company === void 0 ? void 0 : company.install_day_rate) !== null && _e !== void 0 ? _e : 0;
    const days = (_f = company === null || company === void 0 ? void 0 : company.install_days) !== null && _f !== void 0 ? _f : 0;
    const labour = dayRate * days;
    const survey = Math.round(((_g = company === null || company === void 0 ? void 0 : company.survey_cost) !== null && _g !== void 0 ? _g : 0) * 1.20); // add VAT to survey cost
    // const radiators = (estimate.property.noBedrooms + estimate.property.noBathrooms + 2) * 300; // living room + kitchen
    const isScottish = checkIfScottish(lead.property.postcode);
    const inspectionDate = ((_h = lead.epcData) === null || _h === void 0 ? void 0 : _h.inspectionDate) ? new Date((_j = lead.epcData) === null || _j === void 0 ? void 0 : _j.inspectionDate) : undefined;
    // BUS grant for English properties
    const busGrantEligibleReasons = checkIfEligibleForBusGrant(inspectionDate, lead.epc_recommendations);
    // HES grant for Scottish properties
    const hesGrantEligibleReasons = checkIfEligibleForHesGrant(lead.epc_scotland);
    const grant = isScottish ? 0 : -7500;
    const totalLineItems = lead.lead_line_items ? sum(lead.lead_line_items.map(x => Number(x.value))) : 0;
    const totalPrice = (heatPump && hotWaterCylinder) ? totalLineItems + parseFloat(labour.toString()) + parseFloat(heatPump.price.toString()) + parseFloat(hotWaterCylinder.price.toString()) + parseFloat(heatPump.parts.toString()) + grant + survey : 0;
    return {
        hotWaterCylinder,
        heatPump,
        labour,
        survey,
        grant,
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        dayRate,
        days,
        totalWatts,
        sCOP,
        flowTempC,
        CO2SavedKg,
        flightsSaved,
        commutesSaved,
        airChanges,
        yearlyCostCurrentGBP,
        yearlyCostHeatPumpGBP,
        busGrantEligibleReasons,
        hesGrantEligibleReasons,
        defaults,
        totalLineItems,
        totalPrice,
        isScottish
    };
};
export const getNumberOfBedrooms = (lead) => {
    var _a;
    return ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.noBedrooms) ? lead.property.houseOverrides.noBedrooms : lead.property.noBedrooms;
};
export const selectHeatPump = (totalWatts, heatPumps, selectedHeatPumpUUID, heatPumpOverrideUUID, company, designTempC = -3, flowTempC = DEFAULT_FLOW_TEMP_C) => {
    var _a, _b;
    // Filter soft deleted heat pumps and calculate kW using getHeatPumpCapacityAtOutsideTempAndFlowTemp, or use kwatts as a fallback
    const heatPumpsWithkW = heatPumps
        .filter(hp => !hp.deleted_at)
        .map(x => ({
        ...x,
        kwatts: x.range_heat_pump ? getHeatPumpCapacityAtOutsideTempAndFlowTemp(x.range_heat_pump, designTempC, flowTempC).capacityKw : x.kwatts
    }));
    // Order heat pumps by ascending capacity and filter to those that meet the required power
    const orderedHeatPumps = _.orderBy(heatPumpsWithkW, x => (x.kwatts) * 1000);
    const filteredHeatPumps = orderedHeatPumps.filter(x => (x.kwatts) * 1000 >= totalWatts);
    // Select heat pump based on preferred brand or the first one that meets the requirement otherwise
    const defaultBrandHeatPumpUUID = (_a = filteredHeatPumps.find(x => { var _a; return ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid) === (company === null || company === void 0 ? void 0 : company.default_brand_range_uuid); })) === null || _a === void 0 ? void 0 : _a.uuid;
    const calculatedHeatPumpUUID = defaultBrandHeatPumpUUID !== null && defaultBrandHeatPumpUUID !== void 0 ? defaultBrandHeatPumpUUID : (_b = filteredHeatPumps[0]) === null || _b === void 0 ? void 0 : _b.uuid;
    // Return the selected heat pump
    return heatPumpsWithkW.find(x => { var _a; return x.uuid === ((_a = selectedHeatPumpUUID !== null && selectedHeatPumpUUID !== void 0 ? selectedHeatPumpUUID : heatPumpOverrideUUID) !== null && _a !== void 0 ? _a : calculatedHeatPumpUUID); });
};
export const selectCylinder = (numberOfBedrooms, hotWaterCylinders, selectedHotWaterCylinderUUID, hotWaterCylinderOverrideUUID) => {
    var _a;
    const litresRequired = (numberOfBedrooms + 1) * 45;
    // Filter soft deleted cylinders to prevent us selecting one (calcultedHotWaterCylinderUUID) that is no longer on the installers inventory.
    const nonSoftDeletedCylinders = hotWaterCylinders.filter(cyl => !cyl.deleted_at);
    // choose cheapest cylinder that is big enough. In edge cases may mean you select a too big cylinder because it's cheaper than a smaller one, but I don't think many people have enough cylinders set up for this to happen
    const calculatedHotWaterCylinderUUID = (_a = (_.orderBy(nonSoftDeletedCylinders, x => parseInt(x.price.toString())).filter(x => parseInt(x.litres.toString()) >= litresRequired)[0])) === null || _a === void 0 ? void 0 : _a.uuid;
    const hotWaterCylinder = hotWaterCylinders === null || hotWaterCylinders === void 0 ? void 0 : hotWaterCylinders.find(x => { var _a; return x.uuid === ((_a = selectedHotWaterCylinderUUID !== null && selectedHotWaterCylinderUUID !== void 0 ? selectedHotWaterCylinderUUID : hotWaterCylinderOverrideUUID) !== null && _a !== void 0 ? _a : calculatedHotWaterCylinderUUID); });
    return hotWaterCylinder;
};
export const calculatePeakHeatLossW = (lead, company) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19;
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const designConditionDefaults = getDesignConditions(latLng, lead.property.altitudeM, false);
    const designTempC = Number((_c = (_b = (_a = lead.property) === null || _a === void 0 ? void 0 : _a.houseOverrides) === null || _b === void 0 ? void 0 : _b.designTempOverride) !== null && _c !== void 0 ? _c : designConditionDefaults.designTempDefaultC); // FIXME - Find root cause of why this is a string sometimes in the database.
    const airChangesDefault = (_d = company === null || company === void 0 ? void 0 : company.estimate_default_ach) !== null && _d !== void 0 ? _d : DEFAULT_AIR_CHANGES; // Air changes per hour
    const internalTempDefault = (_e = company === null || company === void 0 ? void 0 : company.estimate_default_internal_temp_c) !== null && _e !== void 0 ? _e : DEFAULT_INTERNAL_TEMP_C;
    const heatCapacityOfAir = 0.33; // Physics number used as multiplier for how much heat the air will take away with it.
    const internalTempC = (_h = (_g = (_f = lead.property) === null || _f === void 0 ? void 0 : _f.houseOverrides) === null || _g === void 0 ? void 0 : _g.internalTempOverride) !== null && _h !== void 0 ? _h : internalTempDefault;
    const tempDiff = internalTempC - designTempC;
    const tempDiffPartyWall = internalTempC - 10; // From MCS calculator. Conservative given other side of wall is likely heated. Will account for some thermal bridging though
    const roomHeight = (_k = (_j = lead.property.houseOverrides) === null || _j === void 0 ? void 0 : _j.roomHeight) !== null && _k !== void 0 ? _k : lead.property.roomHeight;
    const floorArea = (_m = (_l = lead.property.houseOverrides) === null || _l === void 0 ? void 0 : _l.floorArea) !== null && _m !== void 0 ? _m : lead.property.floorArea;
    const propertyType = (_p = (_o = lead.property.houseOverrides) === null || _o === void 0 ? void 0 : _o.propertyType) !== null && _p !== void 0 ? _p : lead.property.propertyType;
    const builtForm = (_r = (_q = lead.property.houseOverrides) === null || _q === void 0 ? void 0 : _q.builtForm) !== null && _r !== void 0 ? _r : lead.property.builtForm;
    const wallGroup = (_t = (_s = lead.property.houseOverrides) === null || _s === void 0 ? void 0 : _s.wallGroup) !== null && _t !== void 0 ? _t : lead.property.wallGroup;
    const wallType = (_v = (_u = lead.property.houseOverrides) === null || _u === void 0 ? void 0 : _u.wallType) !== null && _v !== void 0 ? _v : lead.property.wallType;
    const floorType = (_x = (_w = lead.property.houseOverrides) === null || _w === void 0 ? void 0 : _w.floorType) !== null && _x !== void 0 ? _x : lead.property.floorType;
    const loftInsulation = (_z = (_y = lead.property.houseOverrides) === null || _y === void 0 ? void 0 : _y.loftInsulation) !== null && _z !== void 0 ? _z : lead.property.loftInsulation;
    const windowType = (_1 = (_0 = lead.property.houseOverrides) === null || _0 === void 0 ? void 0 : _0.windowType) !== null && _1 !== void 0 ? _1 : lead.property.windowType;
    // Find how conductive each surface is of the house, pulled from EPC and form data.
    // A UValue is how many watts, per degree, per m2 the material will lose.
    // Defaults here account for fact that new builds will have u values defined from the beginning
    // For new builds currently seem to have a lot of 0 values for the U values when there was no EPC first. Fix this before changing to this defaulting to undefined
    const externalWallDefault = lead.property.wallUValue > 0
        ? lead.property.wallUValue
        : calculatedExternalWallUValue(wallType, wallGroup);
    const externalWallUValue = (_4 = (_3 = (_2 = (lead.property)) === null || _2 === void 0 ? void 0 : _2.houseOverrides) === null || _3 === void 0 ? void 0 : _3.externalWallUValueOverride) !== null && _4 !== void 0 ? _4 : externalWallDefault;
    const partyWallDefault = lead.property.wallUValue > 0
        ? Math.min(lead.property.wallUValue, 0.5) // if U value defined for new build walls in EPC data, use the better of that and 0.5
        : 0.5; // hard coded as 0.5 in MCS. That's also the worst case option in RdSAP Table 15
    const partyWallUValue = (_7 = (_6 = (_5 = (lead.property)) === null || _5 === void 0 ? void 0 : _5.houseOverrides) === null || _6 === void 0 ? void 0 : _6.partyWallUValueOverride) !== null && _7 !== void 0 ? _7 : partyWallDefault;
    const windowDefault = lead.property.windowUValue > 0
        ? lead.property.windowUValue
        : windowUValueFromType(windowType);
    const windowsUValue = (_10 = (_9 = (_8 = (lead.property)) === null || _8 === void 0 ? void 0 : _8.houseOverrides) === null || _9 === void 0 ? void 0 : _9.windowsUValueOverride) !== null && _10 !== void 0 ? _10 : windowDefault;
    const floorDefault = lead.property.floorUValue > 0
        ? lead.property.floorUValue
        : floorUValueFromType(floorType);
    const floorUValue = (_13 = (_12 = (_11 = (lead.property)) === null || _11 === void 0 ? void 0 : _11.houseOverrides) === null || _12 === void 0 ? void 0 : _12.floorUValueOverride) !== null && _13 !== void 0 ? _13 : floorDefault;
    const roofDefault = lead.property.roofUValue > 0
        ? lead.property.roofUValue
        : roofUValueFromInsulationThickness(loftInsulation);
    const roofUValue = (_16 = (_15 = (_14 = (lead.property)) === null || _14 === void 0 ? void 0 : _14.houseOverrides) === null || _15 === void 0 ? void 0 : _15.roofUValueOverride) !== null && _16 !== void 0 ? _16 : roofDefault;
    const airChanges = (_19 = (_18 = (_17 = lead.property) === null || _17 === void 0 ? void 0 : _17.houseOverrides) === null || _18 === void 0 ? void 0 : _18.airChangeOverride) !== null && _19 !== void 0 ? _19 : airChangesDefault;
    const defaults = {
        externalWallDefault,
        partyWallDefault,
        windowDefault,
        floorDefault,
        roofDefault,
        airChangesDefault,
        internalTempDefault,
        designTempDefault: designConditionDefaults.designTempDefaultC
    };
    const areas = calculateBuildingElementAreas(roomHeight, builtForm, propertyType, floorArea);
    // Multiply area by temperature difference required.
    // Multiply result by UValue of surface to calculate watts lost.
    const partyWallWatts = Math.round(areas.partyWallAreaM2 * tempDiffPartyWall * partyWallUValue);
    const externalWallWatts = Math.round(areas.externalWallAreaM2 * tempDiff * externalWallUValue);
    const windowWatts = Math.round(areas.windowAndDoorAreaM2 * tempDiff * windowsUValue);
    const floorWatts = Math.round(areas.heatLossFloorAreaM2 * tempDiff * floorUValue);
    const roofWatts = Math.round(areas.roofAreaM2 * tempDiff * roofUValue);
    const ventilationWatts = Math.round(areas.volumeM3 * airChanges * heatCapacityOfAir * tempDiff);
    const totalWatts = partyWallWatts + externalWallWatts + windowWatts + floorWatts + roofWatts + ventilationWatts;
    const htcWPerK = totalWatts / tempDiff;
    return {
        designTempC,
        internalTempC,
        externalWallUValue,
        partyWallUValue,
        windowsUValue,
        floorUValue,
        roofUValue,
        partyWallWatts,
        externalWallWatts,
        windowWatts,
        floorWatts,
        roofWatts,
        ventilationWatts,
        totalWatts,
        airChanges,
        htcWPerK,
        defaults,
        degreeDays: designConditionDefaults.degreeDays
    };
};
export const calculatedExternalWallUValue = (wallType, wallGroupUUID) => {
    var _a, _b, _c;
    const wallGroup = WALL_GROUPS.find(x => x.name === wallGroupUUID);
    if (!wallGroup)
        return 0;
    if (wallGroup.name === CAVITY_WALL)
        return (_b = (_a = CAVITY_WALL_INSULATION.find(x => x.uuid === wallType)) === null || _a === void 0 ? void 0 : _a.u_value) !== null && _b !== void 0 ? _b : 0;
    if (wallGroup.name === TIMBER)
        return wallGroup.u_value;
    const insulationUValue = (_c = SOLID_WALL_INSULATION.find(x => x.uuid === wallType)) === null || _c === void 0 ? void 0 : _c.u_value;
    if (!insulationUValue)
        return wallGroup.u_value; // If we have no value for historic records, just return the material u_value
    return roundTo2dp(1 / ((1 / wallGroup.u_value) + (1 / insulationUValue)));
};
export const calculateBuildingElementAreas = (roomHeightM, builtForm, propertyType, totalFloorAreaM2) => {
    var _a, _b;
    const builtFormObject = BUILT_FORM_TYPES.find(x => x.uuid === builtForm);
    const numberOfPartyWalls = (_a = builtFormObject === null || builtFormObject === void 0 ? void 0 : builtFormObject.numberOfPartyWalls) !== null && _a !== void 0 ? _a : 1;
    const propertyTypeObject = PROPERTY_TYPES.find(x => x.uuid === propertyType);
    const numberOfStories = (_b = propertyTypeObject === null || propertyTypeObject === void 0 ? void 0 : propertyTypeObject.numberOfStoriesDefault) !== null && _b !== void 0 ? _b : 2;
    const averageAreaPerStoreyM2 = totalFloorAreaM2 / numberOfStories;
    const wallLengthM = Math.sqrt(averageAreaPerStoreyM2); // TODO later: don't assume square (differentiate between width and depth)
    //  TODO: check if MCS increases room height to deal with inter-floor space
    const externalWallAreaM2 = wallLengthM * (4 - numberOfPartyWalls) * roomHeightM * numberOfStories * 0.9;
    // currently assuming windows in party walls - TODO: fix this in seperate PR
    const partyWallAreaM2 = wallLengthM * numberOfPartyWalls * roomHeightM * numberOfStories * 0.9;
    const windowAndDoorAreaM2 = wallLengthM * 4 * roomHeightM * numberOfStories * 0.1; // TODO later calculate window area based on average percentage of wall area from RdSAP 2012 and doors somehow sensible
    const heatLossFloorAreaM2 = averageAreaPerStoreyM2;
    const roofAreaM2 = totalFloorAreaM2; // Note this is double what it should be - TODO: fix in a separate PR
    const volumeM3 = totalFloorAreaM2 * roomHeightM;
    return {
        externalWallAreaM2,
        partyWallAreaM2,
        windowAndDoorAreaM2,
        heatLossFloorAreaM2,
        roofAreaM2,
        volumeM3
    };
};
export const calculateYearlyCO2kg = (fuel, yearlykWh) => {
    return yearlykWh * fuel.gCO2PerkWh / 1000;
};
export const calculateYearlyCostGBP = (fuel, yearlykWh) => {
    return (yearlykWh * fuel.pPerkWh + 365.25 * fuel.pPerDay) / 100;
};
export const getFlightsSaved = (CO2SavedKg) => {
    const flightsToSpain = 192;
    return Math.floor(CO2SavedKg / flightsToSpain);
};
export const getCommutesSaved = (CO2SavedKg) => {
    const dailyCommute = 3.6;
    return Math.floor(CO2SavedKg / dailyCommute);
};
export const checkIfEligibleForHesGrant = (epcScotland) => {
    var _a, _b, _c, _d, _e, _f, _g;
    if (!epcScotland)
        return [{ id: 'no_valid', warning: false, hasPassed: false, message: 'Has a valid EPC' }];
    // recommendations for Scottish properties are in the epc_scotland object .improvements field
    const scottishImprovements = makeDictFromScottishImprovements((_a = epcScotland === null || epcScotland === void 0 ? void 0 : epcScotland.improvements) !== null && _a !== void 0 ? _a : '');
    return [
        {
            id: 'cavity_wall',
            warning: !((_b = scottishImprovements.description) === null || _b === void 0 ? void 0 : _b.includes('cavity wall insulation')),
            hasPassed: !((_c = scottishImprovements.description) === null || _c === void 0 ? void 0 : _c.includes('cavity wall insulation')),
            message: !((_d = scottishImprovements.description) === null || _d === void 0 ? void 0 : _d.includes('cavity wall insulation')) ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
        },
        {
            id: 'loft_insulation',
            warning: !((_e = scottishImprovements.description) === null || _e === void 0 ? void 0 : _e.includes('increase loft insulation to 270')),
            hasPassed: !((_f = scottishImprovements.description) === null || _f === void 0 ? void 0 : _f.includes('increase loft insulation to 270')),
            message: !((_g = scottishImprovements.description) === null || _g === void 0 ? void 0 : _g.includes('increase loft insulation to 270')) ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
        }
    ];
};
export const checkIfEligibleForBusGrant = (inspectionDate, recommendations) => {
    // Docs: https://files.bregroup.com/sap/RdSAP_2012_9.94-20-09-2019.pdf page 52 as a reference for improvement-id
    if (!inspectionDate)
        return [{ id: 'no_valid', warning: false, hasPassed: false, message: 'No valid EPC found' }];
    else {
        return [
            {
                id: 'epc_date',
                hasPassed: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99,
                warning: false,
                message: inspectionDate && yearDiff(new Date(), inspectionDate) < 9.99 ? 'EPC is under 10 years old' : 'EPC is more than 10 years old'
            },
            {
                id: 'cavity_wall',
                hasPassed: true,
                warning: recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('cavity')) || x['improvement-id'] === '6'; }),
                message: !recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('cavity')) || x['improvement-id'] === '6'; }) ? 'No recommendation for cavity wall insulation' : 'Cavity wall insulation is recommended on latest EPC'
            },
            {
                id: 'loft_insulation',
                hasPassed: true,
                warning: recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('loft')) || x['improvement-id'] === '5'; }),
                message: !recommendations.some(x => { var _a; return ((_a = x['improvement-id-text']) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes('loft')) || x['improvement-id'] === '5'; }) ? 'No recommendation for loft insulation' : 'Loft insulation is recommended on latest EPC'
            }
        ];
    }
};
