import { chain, sum } from 'lodash';
import { getWallUValue } from './wall';
import { getWindowUValue } from './window';
import { getDoorUValue } from './door';
import { getRooflightUValue } from './rooflight';
import { CEILING_TYPES, FLUE_TYPES, getCeilingUValue, getFloorUValue } from './room';
import { MATERIAL_ELEMENT_NAMES } from './material';
import { ROOM_TYPE_ACH, ROOM_TYPES } from '../../pages/heat_loss/constants';
import { calculateLineLength, getAreaM2 } from '../../pages/heat_loss/floor/code/utils';
export const getHeatTransferCoefficientWattsPerKelvin = (survey, designTempC, groundTempC) => {
    const heatLossWattsPerRoomPerKelvin = survey.floors.flatMap(x => x.rooms.flatMap(y => ({
        room: y,
        value: getRoomWatts(y, x.rooms, designTempC, groundTempC, survey) / (getRoomTemp(y, survey) - designTempC)
    })));
    return sum(heatLossWattsPerRoomPerKelvin.map(x => x.value));
};
export const getTotalHeatLossWatts = (survey, externalTempC, groundTempC) => {
    const totalHeatLossWatts = sum(survey.floors.flatMap(x => x.rooms.flatMap(y => getRoomWatts(y, x.rooms, externalTempC, groundTempC, survey))));
    return totalHeatLossWatts;
};
// Just the total watts for the room
export const getRoomWatts = (room, rooms, externalTempC, groundTempC, survey) => {
    const ventilationHeatLoss = getVentilationHeatLoss(room, externalTempC, survey);
    const conductionHeatLosses = getConductionHeatLossAllElements(room, rooms, externalTempC, groundTempC, survey);
    return sum(conductionHeatLosses.map(x => x.watts)) + ventilationHeatLoss.watts;
};
// Return rows of the heat loss details for each element in the room
export const getConductionHeatLossAllElements = (room, rooms, externalTempC, groundTempC, survey) => {
    const roomTempC = getRoomTemp(room, survey);
    const roomHeightM = getAverageRoomHeightM(room);
    const wallHeatLosses = room.walls.map((x, i) => {
        const nextWallIndex = room.walls.length - 1 === i ? 0 : i + 1;
        return getWallWindowDoorHeatLosses(x, room.walls[nextWallIndex], rooms, roomHeightM, roomTempC, externalTempC, survey);
    });
    const wallHeatLossesSorted = wallHeatLosses.flat().sort((a, b) => a.elementName.localeCompare(b.elementName));
    const ceilingHeatLosses = getCeilingHeatLosses(room, roomTempC, externalTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage);
    const floorHeatLoss = getFloorHeatLoss(room, roomTempC, externalTempC, groundTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage);
    return [floorHeatLoss, ...ceilingHeatLosses, ...wallHeatLossesSorted];
};
export const getWallWindowDoorHeatLosses = (wall, linkedWall, rooms, roomHeightM, roomTempC, externalTempC, survey) => {
    const wallHeatLoss = getWallHeatLoss(wall, linkedWall, rooms, roomHeightM, roomTempC, externalTempC, survey);
    const otherSideTempC = getOtherSideTempWall(wall, rooms, externalTempC, survey);
    // could move this into the window and door functions later to aid testing if we want
    const windowHeatLosses = wall.windows.map(x => getWindowHeatLoss(x, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage));
    const doorHeatLosses = wall.doors.map(x => getDoorHeatLoss(x, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage));
    return [wallHeatLoss, ...windowHeatLosses, ...doorHeatLosses];
};
export const getNetWallAreaM2 = (wall, linkedWall, roomHeightM) => {
    const lineLength = calculateLineLength(wall.x, wall.y, linkedWall.x, linkedWall.y);
    const grossAreaM2 = lineLength * roomHeightM;
    const totalWindowAreaM2 = sum(wall.windows.map(getWindowAreaM2));
    const totalDoorAreaM2 = sum(wall.doors.map(getDoorAreaM2));
    return grossAreaM2 - totalWindowAreaM2 - totalDoorAreaM2;
};
export const getWallHeatLoss = (wall, linkedWall, rooms, roomHeightM, roomTempC, externalTempC, survey) => {
    const netWallAreaM2 = getNetWallAreaM2(wall, linkedWall, roomHeightM);
    const uValueWPerM2K = getWallUValue(wall);
    const otherSideTempC = getOtherSideTempWall(wall, rooms, externalTempC, survey);
    const elementName = MATERIAL_ELEMENT_NAMES[wall.material.applicable_to] + 's';
    return getConductionHeatLoss(elementName, wall.material, netWallAreaM2, uValueWPerM2K, roomTempC, otherSideTempC, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage);
};
export const getWindowHeatLoss = (window, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    const areaM2 = getWindowAreaM2(window);
    const uValueWPerM2K = getWindowUValue(window);
    return getConductionHeatLoss('Windows', window.material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage);
};
export const getRooflightHeatLoss = (rooflight, ceilingTypeUUID, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    const areaM2 = getRooflightAreaM2(rooflight);
    const uValueWPerM2K = getRooflightUValue(rooflight, ceilingTypeUUID);
    return getConductionHeatLoss('Rooflights', rooflight.material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage);
};
export const getDoorHeatLoss = (door, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    const areaM2 = getDoorAreaM2(door);
    const uValueWPerM2K = getDoorUValue(door);
    return getConductionHeatLoss('Doors', door.material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage);
};
export const getFloorHeatLoss = (room, roomTempC, externalTempC, groundTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    var _a, _b;
    const areaM2 = getAreaM2(room.walls.map(x => ({ x: x.x, y: x.y })));
    const uValueWPerM2K = getFloorUValue(room);
    const otherSideTempC = getOtherSideTempFloor(room.floor_material, externalTempC, groundTempC, roomTempC, room.floor_other_side_temp_override_c);
    let elementName;
    if (((_a = room.floor_material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'ground-floor') {
        elementName = 'Ground Floor';
    }
    else if (((_b = room.floor_material) === null || _b === void 0 ? void 0 : _b.applicable_to) === 'exposed-floor') {
        elementName = 'Exposed Floor';
    }
    else {
        elementName = 'Intermediate Floor';
    }
    return getConductionHeatLoss(elementName, room.floor_material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage);
};
export const getCeilingHeatLosses = (room, roomTempC, externalTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    var _a, _b;
    // `later use ceiling type to get more accurate area here for vaulted rooms.
    // Currently we just adjust the average height to account for vaulted ceilings so the volume is right and the wall height (and hence area) is increased but the ceiling area is not.
    // U values of vaulted ceilings are often similar to those for walls so this isn't a huge issue for now.
    const uValueWPerM2K = getCeilingUValue(room);
    const otherSideTempC = getOtherSideTempCeiling(room.ceiling_material, externalTempC, roomTempC, room.ceiling_other_side_temp_override_c);
    let areaM2 = getCeilingAreaM2(room.walls);
    let rooflightHeatLosses = [];
    // if ceiling is a roof (not intermediate ceiling) then we need to account for rooflights
    if (((_a = room.ceiling_material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'roof') {
        // subtract the area of all rooflights from the ceiling area
        const rooflightsAreaM2 = room.rooflights.reduce((acc, rooflight) => acc + getRooflightAreaM2(rooflight), 0);
        areaM2 -= rooflightsAreaM2;
        rooflightHeatLosses = room.rooflights.map(rl => getRooflightHeatLoss(rl, room.ceiling_type_uuid, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage));
    }
    const elementName = ((_b = room.ceiling_material) === null || _b === void 0 ? void 0 : _b.applicable_to) === 'roof' ? 'Roof' : 'Intermediate Ceiling';
    return [
        getConductionHeatLoss(elementName, room.ceiling_material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage),
        ...rooflightHeatLosses
    ];
};
const getConductionHeatLoss = (elementName, material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    const tempDiffK = roomTempC - otherSideTempC;
    const watts = applyIntermittentHeatingCorrection(areaM2 * uValueWPerM2K * tempDiffK, intermittentHeating, intermittentHeatingOversizeFactorPercentage);
    const heatLoss = { elementName, material, areaM2, uValueWPerM2K, roomTempC, otherSideTempC, tempDiffK, watts };
    return heatLoss;
};
export const getVentilationHeatLoss = (room, externalTempC, survey) => {
    const roomTempC = getRoomTemp(room, survey);
    const volumeM3 = getRoomVolumeM3(room);
    const ACH = getRoomACH(room, survey);
    const tempDiffK = roomTempC - externalTempC;
    const heatRecoveryPercentage = survey.mvhr_installed ? 0.5 : 0; // this is a bodge based on CIBSE section 3.5.4.3. Reconsider when you allow people to enter infiltration values and then add in MVHR?
    const heatLossWatts = (1 / 3) * volumeM3 * ACH * tempDiffK * (1 - heatRecoveryPercentage);
    const wattsWithIntermittentHeating = applyIntermittentHeatingCorrection(heatLossWatts, survey.intermittent_heating, survey.intermittent_heating_oversize_factor_percentage);
    const heatLoss = { elementName: 'Ventilation', volumeM3, ACH, roomTempC, externalTempC, tempDiffK, watts: wattsWithIntermittentHeating, heatRecoveryPercentage };
    return heatLoss;
};
export const applyIntermittentHeatingCorrection = (watts, intermittentHeating, intermittentHeatingOversizeFactorPercentage) => {
    if (intermittentHeating) {
        return watts * (1 + intermittentHeatingOversizeFactorPercentage / 100);
    }
    return watts;
};
export const getRoomTemp = (room, survey, onlyDefault = false) => {
    if (!room)
        return 0;
    if (room.indoor_temp_override_c !== undefined && !onlyDefault)
        return room.indoor_temp_override_c;
    if (!survey.use_cibse_indoor_temps)
        return survey.indoor_temp_overall_c;
    const roomType = ROOM_TYPES.find(x => x.uuid === room.room_type_uuid);
    if (survey.age_band && (survey.age_band.ach_age_key === 'ach_post_2006'))
        return roomType.indoor_temp_post_2006_c;
    return roomType.indoor_temp_c;
};
export const getOtherSideTempWall = (wall, rooms, externalTempC, survey) => {
    var _a;
    if (wall.other_side_temp_override_c)
        return wall.other_side_temp_override_c;
    if (((_a = wall.material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'party-wall')
        return 10; // TODO: make settings value
    if (wall.other_room_uuid)
        return getRoomTemp(rooms.find(x => x.uuid === wall.other_room_uuid), survey);
    return externalTempC;
};
export const getOtherSideTempFloor = (floorMaterial, externalTempC, groundTempC, roomTempC, otherSideTempOverrideC) => {
    if (otherSideTempOverrideC)
        return otherSideTempOverrideC;
    if (floorMaterial.applicable_to === 'intermediate-floor-and-ceiling') {
        // Not aligning floors so if intermediate floor assume no heat loss across it for now
        return roomTempC;
    }
    if (floorMaterial.applicable_to === 'exposed-floor') {
        // CIBSE guide section 3.5.3.3, Adjoining properties and unheated spaces
        return 10;
    }
    if (floorMaterial.applicable_to === 'ground-floor') {
        if (floorMaterial.extra_data.construction === 'Suspended') {
            return externalTempC;
        }
        // If not suspended assume solid. Will apply for custom ground floors and new build ground floors
        return groundTempC;
        //   Notes on this here https://www.notion.so/spruce-energy/Ground-floor-U-values-96db7fae58364ac1ab05db0890fcc8dc?pvs=4
    }
    throw new Error(`Invalid floor material type ${floorMaterial.applicable_to}`);
};
export const getOtherSideTempCeiling = (ceilingMaterial, externalTempC, roomTempC, otherSideTempOverrideC) => {
    if (otherSideTempOverrideC)
        return otherSideTempOverrideC;
    if (ceilingMaterial.applicable_to === 'roof')
        return externalTempC;
    // Not aligning floors yet so if it's an internal ceiling just assume no heat loss across so rooms total is correct
    // Later use temp of room on other side
    if (ceilingMaterial.applicable_to === 'intermediate-floor-and-ceiling')
        return roomTempC;
    // Later add party ceiling/floor for flats
    throw new Error(`Invalid ceiling material type ${ceilingMaterial.applicable_to}`);
};
export const getRoomACH = (room, survey, onlyDefault = false) => {
    return getRoomACHGranularInputs(room.ach_override, room.flue_type_uuid, getRoomVolumeM3(room), survey.use_cibse_air_change_values, survey.air_change_per_hour_overall, room.room_type_uuid, survey.air_change_year_uuid, onlyDefault);
};
// Broken down inputs to facilitate testing. Will also make it easier to memo-ise later if needed
export const getRoomACHGranularInputs = (roomAchOverride, flueTypeUuid, roomVolumeM3, useCIBSEAirChangeValues, airChangesSetValueSurvey, roomTypeUuid, airChangeYearUuid, onlyDefault = false) => {
    if (roomAchOverride !== undefined && !onlyDefault)
        return roomAchOverride;
    // Unless user has overwritten ACH value in the room, use the flue based ACH value if they have a flue
    if (flueTypeUuid && flueTypeUuid !== 'no') {
        const flueType = FLUE_TYPES.find(x => x.uuid === flueTypeUuid);
        return roomVolumeM3 <= 40 ? flueType.ach_volume_less_than_or_equal_to_40_m3 : flueType.ach_volume_greater_than_40_m3;
    }
    if (!useCIBSEAirChangeValues)
        return airChangesSetValueSurvey;
    return ROOM_TYPE_ACH.find(x => x.room_uuid === roomTypeUuid && x.age_uuid === airChangeYearUuid).value;
};
export const getRoomVolumeM3 = (room) => {
    const roomHeightM = getAverageRoomHeightM(room);
    const areaM3 = getFloorAreaM2(room.walls);
    return areaM3 * roomHeightM;
};
export const getFloorAreaM2 = (walls) => {
    return getAreaM2(walls.map(x => ({ x: x.x, y: x.y })));
};
export const getCeilingAreaM2 = (walls) => {
    // FIXME: `later use ceiling type to get more accurate area here for vaulted rooms.
    return getAreaM2(walls.map(x => ({ x: x.x, y: x.y })));
};
export const getExposedPerimeterM = (walls) => {
    var _a;
    /*
    From BRE 443 Conventions for U value calculations 2006
    There are newer versions (pay walled!), but I doubt this has changed
    https://files.bregroup.com/bre-co-uk-file-library-copy/filelibrary/rpts/uvalue/BR_443_(2006_Edition).pdf
    Section 9
    The U-value for floors (including basement floors) depends on the exposed perimeter and the area of the floor.
     The perimeter should include the length of all exposed walls bounding the heated space and also any walls between
      the heated space and an unheated space – the floor losses are calculated as if the unheated space were not present.
       Walls to other spaces that can reasonably be assumed to be heated to the same temperature (e.g. the separating
        wall to an adjacent house) should not be included in the perimeter.
     */
    let total = 0;
    for (let i = 0, l = walls.length; i < l; i++) {
        if (((_a = walls[i].material) === null || _a === void 0 ? void 0 : _a.applicable_to) === 'external-wall') {
            const nextIndex = i < l - 1 ? i + 1 : 0; // go back to start if at end
            total += calculateLineLength(walls[i].x, walls[i].y, walls[nextIndex].x, walls[nextIndex].y);
        }
    }
    return total;
};
export const getAverageRoomHeightM = (room) => {
    const ceilingType = CEILING_TYPES.find(x => x.uuid === room.ceiling_type_uuid);
    return ceilingType.getAverageRoomHeightM(room);
};
const getWindowAreaM2 = (window) => {
    // convert mm2 to m2
    return window.width_mm * window.height_mm / 1000000;
};
const getRooflightAreaM2 = (rooflight) => {
    const areaMM = rooflight.width_mm * rooflight.height_mm;
    // convert mm2 to m2
    return areaMM / 1000000;
};
const getDoorAreaM2 = (door) => {
    return door.width_mm * door.height_mm / 1000000;
};
// Formulae for manipulating and combining heat loss values
// Combine the volumes and heat losses for rooms with the same ACH. Ignore temp and heat recovery percentage for now
export const combineVentilationHeatLosses = (heatLosses) => {
    return chain(heatLosses)
        .groupBy(x => `${x.ACH}`)
        .map((values, key) => ({
        ...values[0],
        volumeM3: sum(values.map(x => x.volumeM3)),
        watts: sum(values.map(x => x.watts))
    }))
        .value();
};
// Combine the areas for items that have the same properties so can present them concisely. Sort the results.
export const combineSortConductionHeatLosses = (heatLosses, differentiateOnTempDiff = true) => {
    const combined = combineConductionHeatLosses(heatLosses, differentiateOnTempDiff);
    const sorted = sortHeatLosses(combined);
    const uniqueNames = renameDuplicates(sorted);
    return uniqueNames;
};
export const combineConductionHeatLosses = (heatLosses, differentiateOnTempDiff = true) => {
    // Only differentiate on U value if different at 2 decimal places
    return chain(heatLosses)
        .groupBy(x => { var _a, _b; return differentiateOnTempDiff ? `${x.elementName}_${(_a = x.uValueWPerM2K) === null || _a === void 0 ? void 0 : _a.toFixed(2)}_${x.tempDiffK}` : `${x.elementName}_${(_b = x.uValueWPerM2K) === null || _b === void 0 ? void 0 : _b.toFixed(2)}`; })
        .map((values, key) => ({
        ...values[0],
        areaM2: sum(values.map(x => x.areaM2)),
        watts: sum(values.map(x => x.watts))
    }))
        .value();
};
// Sort conduction heat losses based on element names in an intuitive order. Run before combine so the additional number doesn't affect the sorting.
export const sortHeatLosses = (heatLosses) => {
    const order = ['Ground Floor', 'Exposed Floor', 'Intermediate Floor', 'Intermediate Ceiling', 'Roof', 'External Walls', 'Internal Walls', 'Party Walls', 'Windows', 'Rooflights', 'Doors'];
    return heatLosses.sort((a, b) => order.indexOf(a.elementName) - order.indexOf(b.elementName));
};
// Rename duplicates of the same element name - add a number after the name if multiple
const renameDuplicates = (heatLosses) => {
    const nameTotalCounts = countElementNames(heatLosses);
    const nameCounts = {};
    return heatLosses.map(item => {
        if (nameTotalCounts[item.elementName] > 1) {
            // If there is more than 1 of them then number all of them
            if (nameCounts[item.elementName]) {
                nameCounts[item.elementName]++;
            }
            else {
                nameCounts[item.elementName] = 1;
            }
            return { ...item, elementName: `${item.elementName} ${nameCounts[item.elementName]}` };
        }
        else {
            // If there is only 1 of them then don't number them
            return item;
        }
    });
};
const countElementNames = (heatLosses) => {
    const nameCounts = {};
    heatLosses.forEach(item => {
        if (nameCounts[item.elementName]) {
            nameCounts[item.elementName]++;
        }
        else {
            nameCounts[item.elementName] = 1;
        }
    });
    return nameCounts;
};
// Combine heat losses into just element names and watt totals for the progress charts
// For chart don't want to differentiate between same element type with different U values
export const combineHeatLossesForProgressChart = (conductionHeatLosses, ventilationHeatLosses) => {
    const sortedConductionHeatLosses = sortHeatLosses(conductionHeatLosses);
    const heatLosses = [...sortedConductionHeatLosses, ...ventilationHeatLosses];
    return chain(heatLosses)
        .groupBy(x => x.elementName)
        .map((values, key) => ({
        name: key,
        value: sum(values.map(x => x.watts))
    })).value();
};
