import React, { useState } from 'react';
import { toastError } from './components/indicators_and_messaging/toast';
import { noop } from 'lodash';
import { useNavigation } from './code/use_navigation';
export const AppContext = React.createContext({
    globalState: {
        source: ''
    },
    setGlobalState: noop,
    setError: noop,
    currentPath: '',
    navigateTo: noop
});
export const AppProvider = ({ children }) => {
    const { currentPath, navigateTo } = useNavigation();
    const [globalState, setGlobalState] = useState({
        source: ''
    });
    const setError = (e) => {
        var _a, _b, _c, _d, _e;
        const axiosError = e;
        if (((_a = axiosError.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            // TODO: logout setGlobalState((prev) => ({ ...prev, isLoggedIn: false }));
            // setGlobalState((prev) => ({ ...prev, toastState: toastError("You are not logged in.") }));
            // return;
        }
        let errorMessage = 'We had an issue fulfilling that request, please contact joseph@spruce.eco.';
        if ((_b = axiosError.response) === null || _b === void 0 ? void 0 : _b.data) {
            errorMessage = ((_c = axiosError.response.data.detail) === null || _c === void 0 ? void 0 : _c.length) > 0
                ? axiosError.response.data.detail
                : React.createElement("ul", null, ((_d = axiosError.response.data.errors) === null || _d === void 0 ? void 0 : _d.map(x => x.detail)).map(x => React.createElement("li", { key: x }, x)));
        }
        setGlobalState((prev) => ({ ...prev, toastState: toastError(errorMessage) }));
        if (((_e = axiosError.response) === null || _e === void 0 ? void 0 : _e.status) !== 401)
            throw e;
    };
    return (React.createElement(AppContext.Provider, { value: { currentPath, navigateTo, globalState, setGlobalState, setError } }, children));
};
