import { noop } from 'lodash';
import React, { useState } from 'react';
import { Circle, Group, Line, Text } from 'react-konva';
import { FONT_SIZE, gray900, indigo600 } from './code/constants';
import { getDistance, getTextDimensions, LineWithText, snapLine, snapToAngle } from './line_with_text';
import { calculateNewTouchingWalls, getNextWall, removeInvalidRoomGroups, simplifyRooms } from './code/utils';
import { getIntersectingShapes } from './floor_canvas/get_intersecting_shapes';
import { calculateCentroid, SNAPPING_TOLERANCE_PIXELS } from '../constants';
export const RoomDrawing = ({ wallsWithoutDupes, room, floor, dragStopped, currentWall, stageScale, setPage, setCurrentWallId, setWalls, currentRoomId, currentRoom, setCurrentRoomId, addEvent, touches, isDrawing, setGuidelines, defaultMaterials, setIntersectingShapes }) => {
    var _a;
    // Drag move updates the floor value each time, so onDragStart we store the old floor so we can fully revert back for undo.
    const [oldFloor, setOldFloor] = useState();
    // onDragStart = set tempRoom.
    // onDragEnd = update the floor to tempRoom and reset tempRoom.
    // Updates to indexedDb should be treated like API requests, this allows us to make the call only when the action is finished.
    const [tempRoom, setTempRoom] = useState();
    const onDragEnd = () => {
        const roomsWithTemp = floor.rooms.map(x => x.uuid === (tempRoom === null || tempRoom === void 0 ? void 0 : tempRoom.uuid) ? tempRoom : x);
        const intersectingShapes = getIntersectingShapes(roomsWithTemp);
        // simplify rooms, if we split a wall and then drag the shape away again, we need to simplify any splits.
        if (intersectingShapes.length === 0) {
            const simplifiedRooms = simplifyRooms(roomsWithTemp);
            const newRooms = calculateNewTouchingWalls(simplifiedRooms, defaultMaterials);
            const removedInvalidRoomGroups = removeInvalidRoomGroups(newRooms);
            const events = [{ type: 'FLOOR', action: 'UPDATE', oldValue: oldFloor, newValue: { ...floor, rooms: removedInvalidRoomGroups } }];
            addEvent(events);
        }
        else {
            addEvent([{ type: 'FLOOR', action: 'UPDATE', oldValue: oldFloor, newValue: { ...floor, rooms: roomsWithTemp } }]);
        }
        setIntersectingShapes(intersectingShapes);
        // Incredibly annoying, but even await addEvent the order will be:
        // setSurvey, setTempRoom, tempRoom = undefined, survey = new survey with temp room
        // Therefore set a very small timeout to prevent a flicker where user would see old room when temp room is cleared but before survey update has happened.
        setTimeout(() => {
            setTempRoom(undefined);
            setGuidelines([]);
        }, 50);
    };
    const onDragMove = (e) => onDragInner(e);
    const calculatedRoom = tempRoom !== null && tempRoom !== void 0 ? tempRoom : room;
    const trueRoomX = calculatedRoom.x * stageScale;
    const trueRoomY = calculatedRoom.y * stageScale;
    const onDragInner = (e) => {
        if (e.currentTarget === e.target) {
            const newPos = e.currentTarget.position();
            e.currentTarget.position(({ x: trueRoomX, y: trueRoomY }));
            for (const wall of room.walls) {
                const nextWall = getNextWall(wall, room.walls);
                const trueX = (wall.x * stageScale) + newPos.x;
                const trueY = (wall.y * stageScale) + newPos.y;
                const trueNextX = (nextWall.x * stageScale) + newPos.x;
                const trueNextY = (nextWall.y * stageScale) + newPos.y;
                const snapped = snapLine({
                    p1: { x: trueX, y: trueY },
                    p2: { x: trueNextX, y: trueNextY }
                }, floor.rooms, stageScale, room.walls.map(x => x.uuid));
                if (snapped) {
                    const newPosX = snapped.newLine.p1.x - (wall.x * stageScale);
                    const newPosY = snapped.newLine.p1.y - (wall.y * stageScale);
                    setTempRoom(prev => ({
                        ...prev,
                        x: Math.round(newPosX / stageScale),
                        y: Math.round(newPosY / stageScale)
                    }));
                    setGuidelines(snapped.guidelines);
                    return;
                }
            }
            setGuidelines([]);
            setTempRoom(prev => ({
                ...prev,
                x: Math.round(newPos.x / stageScale),
                y: Math.round(newPos.y / stageScale)
            }));
        }
    };
    const onPointDragMove = (wallUUID, e, setGuidelines, stageScale) => {
        var _a;
        const currentPoint = tempRoom.walls.find(x => x.uuid === wallUUID);
        const trueX = (currentPoint.x * stageScale) + trueRoomX;
        const trueY = (currentPoint.y * stageScale) + trueRoomY;
        if (e.currentTarget === e.target) {
            const newPos = e.target.position();
            e.currentTarget.position(({ x: trueX, y: trueY }));
            const snappedPositions = snapPoint(newPos, floor.rooms, stageScale, [currentPoint.uuid]);
            const newPoint = (_a = snappedPositions === null || snappedPositions === void 0 ? void 0 : snappedPositions.newPoint) !== null && _a !== void 0 ? _a : newPos;
            snappedPositions ? setGuidelines(snappedPositions.guidelines) : setGuidelines([]);
            const newRoom = { ...tempRoom, walls: tempRoom.walls.map(x => x.uuid === wallUUID ? { ...currentPoint, x: Math.round((newPoint.x - trueRoomX) / stageScale), y: Math.round((newPoint.y - trueRoomY) / stageScale) } : x) };
            setTempRoom(newRoom);
        }
    };
    const isCurrentRoom = room.uuid === currentRoomId;
    const onClick = (e) => {
        setCurrentRoomId(room.uuid);
        setCurrentWallId('');
        setPage('ROOM_DETAILS');
    };
    const points = room.walls.map(x => [x.x * stageScale, x.y * stageScale]);
    const [centerX, centerY] = calculateCentroid(points);
    return React.createElement(React.Fragment, null,
        React.createElement(Group, { opacity: isCurrentRoom ? 1 : 0.5, onTap: isDrawing ? noop : onClick, onClick: isDrawing ? noop : onClick },
            React.createElement(Line, { points: [...(_a = calculatedRoom.walls) === null || _a === void 0 ? void 0 : _a.map(x => [x.x * stageScale, x.y * stageScale]).flat()], fill: 'white', draggable: isCurrentRoom && touches < 2, onDragStart: (e) => {
                    setOldFloor(floor);
                    setTempRoom(room);
                }, onDragEnd: onDragEnd, onDragMove: onDragMove, x: trueRoomX, y: trueRoomY, closed: true }),
            !isCurrentRoom && React.createElement(Text, { x: trueRoomX + centerX - (getTextDimensions(calculatedRoom.text, true).width / 2), y: trueRoomY + centerY - FONT_SIZE - 2, fontStyle: 'bold', fontFamily: 'Manrope', fill: gray900, fontSize: FONT_SIZE, text: calculatedRoom.text }),
            !isCurrentRoom && React.createElement(Text, { x: trueRoomX + centerX - (getTextDimensions(calculatedRoom.roomText).width / 2), y: trueRoomY + centerY, fontFamily: 'Manrope', fill: indigo600, fontSize: FONT_SIZE, text: calculatedRoom.roomText })),
        calculatedRoom.walls.map((x, i) => {
            const otherRoom = floor.rooms.find(y => y.uuid === x.other_room_uuid);
            if (calculatedRoom.room_group_uuid !== undefined && calculatedRoom.room_group_uuid === (otherRoom === null || otherRoom === void 0 ? void 0 : otherRoom.room_group_uuid))
                return null;
            const innerText = [
                x.windows.length > 0 ? `${x.windows.length} W` : '',
                x.doors.length > 0 ? `${x.doors.length} D` : ''
            ].filter(x => x.length > 0).join(', ');
            return React.createElement(LineWithText, { onDragStart: () => setTempRoom(currentRoom), otherRoomId: x.other_room_uuid, onDragEnd: onDragEnd, draggable: isCurrentRoom && touches < 2 && (currentWall === null || currentWall === void 0 ? void 0 : currentWall.uuid) === x.uuid && !dragStopped, isCurrentWall: (currentWall === null || currentWall === void 0 ? void 0 : currentWall.uuid) === x.uuid, scale: { x: stageScale, y: stageScale }, innerText: innerText, key: i, onClick: isCurrentRoom && !isDrawing ? () => { console.log(x.uuid); setCurrentWallId(x.uuid); setPage('WALL_MATERIALS'); } : noop, x: trueRoomX, y: trueRoomY, wallIndex: i, walls: calculatedRoom.walls, setWalls: (w) => setTempRoom(prev => ({ ...prev, walls: w })), setCurrentWallId: setCurrentWallId, setPage: setPage, isCurrentRoom: isCurrentRoom, rooms: floor.rooms, room: calculatedRoom, floor: floor, stageScale: stageScale, setGuidelines: setGuidelines });
        }),
        (currentRoom === null || currentRoom === void 0 ? void 0 : currentRoom.uuid) === calculatedRoom.uuid && calculatedRoom.walls.map(x => {
            const truePointX = x.x * stageScale + trueRoomX;
            const truePointY = x.y * stageScale + trueRoomY;
            return React.createElement(Circle, { key: x.uuid, draggable: true, onDragStart: () => setTempRoom(currentRoom), onDragMove: (e) => onPointDragMove(x.uuid, e, setGuidelines, stageScale), onDragEnd: onDragEnd, fill: indigo600, radius: 5, x: truePointX, y: truePointY });
        }));
};
export const snapPoint = (point, rooms, stageScale, excludeWallUUIDs = [], snappingTypes = ['POINT', 'VERTICAL_HORIZONTAL', 'ANGLE', 'ANGLE_INTERSECTION']) => {
    const pointTolerance = SNAPPING_TOLERANCE_PIXELS;
    const matchedPoints = [];
    const wallSegments = [...rooms.flatMap(r => {
            const walls = r.walls.map(w => {
                const nextWall = getNextWall(w, r.walls);
                return {
                    p1: { uuid: w.uuid, x: (w.x + r.x) * stageScale, y: (w.y + r.y) * stageScale },
                    p2: { uuid: nextWall.uuid, x: (nextWall.x + r.x) * stageScale, y: (nextWall.y + r.y) * stageScale }
                };
            });
            return walls;
        })].filter(x => !excludeWallUUIDs.includes(x.p1.uuid));
    const points = wallSegments.map(x => x.p1);
    const angleWallSegments = wallSegments.filter(x => !excludeWallUUIDs.includes(x.p2.uuid));
    if (snappingTypes.includes('POINT')) {
        const anyClosePoint = points.find(x => Math.abs(x.x - point.x) < pointTolerance &&
            Math.abs(x.y - point.y) < pointTolerance);
        if (anyClosePoint) {
            matchedPoints.push({ newPoint: anyClosePoint, guidelines: [{ p1: anyClosePoint, p2: anyClosePoint }], type: 'POINT', distance: 0 });
        }
    }
    if (snappingTypes.includes('VERTICAL_HORIZONTAL')) {
        const distances = points.map(x => ({ point: x, distance: getDistance(x, point) }));
        const closestX = distances.filter(x => Math.abs(x.point.x - point.x) < pointTolerance).sort((x, y) => x.distance - y.distance)[0];
        const closestY = distances.filter(x => Math.abs(x.point.y - point.y) < pointTolerance).sort((x, y) => x.distance - y.distance)[0];
        if (closestX && closestY) {
            const newPosition = { x: closestX.point.x, y: closestY.point.y };
            matchedPoints.push({
                type: 'VERTICAL_HORIZONTAL',
                newPoint: newPosition,
                distance: 0,
                guidelines: [
                    { p1: newPosition, p2: closestY.point },
                    { p1: newPosition, p2: closestX.point }
                ]
            });
        }
        if (closestX) {
            const newPosition = { x: closestX.point.x, y: point.y };
            matchedPoints.push({ type: 'VERTICAL', newPoint: newPosition, guidelines: [{ p1: newPosition, p2: closestX.point }], distance: closestX.distance });
        }
        if (closestY) {
            const newPosition = { x: point.x, y: closestY.point.y };
            matchedPoints.push({ type: 'HORIZONTAL', newPoint: newPosition, guidelines: [{ p1: newPosition, p2: closestY.point }], distance: closestY.distance });
        }
    }
    if (snappingTypes.includes('ANGLE')) {
        for (let i = 0; i < angleWallSegments.length; i++) {
            for (let j = i + 1; j < angleWallSegments.length; j++) {
                const intersection = getExtendedIntersection(angleWallSegments[i], angleWallSegments[j]);
                if (intersection && getDistance(intersection, point) < pointTolerance) {
                    matchedPoints.push({
                        type: 'ANGLE_INTERSECTION',
                        newPoint: intersection,
                        guidelines: [
                            { p1: intersection, p2: angleWallSegments[i].p1 },
                            { p1: intersection, p2: angleWallSegments[j].p1 }
                        ],
                        distance: getDistance(intersection, point)
                    });
                }
            }
        }
        for (const segment of angleWallSegments) {
            const counterClockwiseCheck = snapToAngle(segment, point);
            if (counterClockwiseCheck === null || counterClockwiseCheck === void 0 ? void 0 : counterClockwiseCheck.newPoint) {
                matchedPoints.push(counterClockwiseCheck);
            }
        }
    }
    const pointMatch = matchedPoints.find(x => x.type === 'POINT');
    if (pointMatch)
        return pointMatch;
    const verticalHorizontal = matchedPoints.find(x => x.type === 'VERTICAL_HORIZONTAL');
    if (verticalHorizontal)
        return verticalHorizontal;
    const angleIntersectionMatch = matchedPoints.find(x => x.type === 'ANGLE_INTERSECTION');
    if (angleIntersectionMatch)
        return angleIntersectionMatch;
    const angleMatch = matchedPoints.find(x => x.type === 'ANGLE');
    if (angleMatch)
        return angleMatch;
    const closestHorizontalVertical = matchedPoints
        .filter(x => x.type === 'VERTICAL' || x.type === 'HORIZONTAL')
        .sort((x, y) => x.distance - y.distance)[0];
    if (closestHorizontalVertical)
        return closestHorizontalVertical;
};
const getExtendedIntersection = (segment1, segment2) => {
    const { p1: p1A, p2: p2A } = segment1;
    const { p1: p1B, p2: p2B } = segment2;
    const denominator = (p2A.x - p1A.x) * (p2B.y - p1B.y) - (p2A.y - p1A.y) * (p2B.x - p1B.x);
    if (Math.abs(denominator) < 1e-6) {
        // Lines are parallel, no intersection
        return null;
    }
    const ua = ((p2B.x - p1B.x) * (p1A.y - p1B.y) - (p2B.y - p1B.y) * (p1A.x - p1B.x)) / denominator;
    // Calculate the intersection point
    const x = p1A.x + ua * (p2A.x - p1A.x);
    const y = p1A.y + ua * (p2A.y - p1A.y);
    return { x, y };
};
