import React, { useContext, useEffect, useRef, useState } from 'react';
import { ClickableCard } from '../../components/content_display/card';
import { calculateEstimate, DEFAULT_FLOW_TEMP_C } from '../../code/calculate_estimate';
import { useNavigate, useRouteLoaderData } from 'react-router-dom';
import { AdminContext } from '../admin/admin_layout';
import { getAddressIncludingPostcode, getLead } from '../../code/models/lead';
import { formatPrice } from '../../code/format_price';
import { checkIfScottish } from '../../code/models/address';
import { ensureLeadHasEPCRecommendations, ensureLeadHasLocation3D, ensurePropertySurveyBackwardsCompatibility, ESTIMATE_MAPPED_ACH, ESTIMATE_MAPPED_AGE_BAND, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS, ESTIMATE_MAPPED_BUILT_FORM, ESTIMATE_MAPPED_DESIGN_TEMP, ESTIMATE_MAPPED_FLOW_TEMP_C, ESTIMATE_MAPPED_FUEL_TYPE, ESTIMATE_MAPPED_HEAT_PUMP, ESTIMATE_MAPPED_HOT_WATER_CYLINDER, ESTIMATE_MAPPED_INDOOR_TEMP, ESTIMATE_MAPPED_MATERIALS, ESTIMATE_MAPPED_PROPERTY_TYPE, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS } from '../../code/models/property';
import { getHeatTransferCoefficientWattsPerKelvin, getRoomWatts, getTotalHeatLossWatts } from '../../code/models/heat_loss';
import { FloorPage } from './floor/floor';
import { chain, noop, sum } from 'lodash';
import { DesignHome } from './design_home';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faList, faPlus } from '@fortawesome/free-solid-svg-icons';
import { getExposedFloorMaterial, instantiateMaterialSet, mapEstimateMaterials } from '../../code/models/material';
import { EmitterDesignPage, getRadiatorBadge } from './design/pages/emitter_design_page';
import { getEmitterWatts, RADIATOR_MODELS } from '../../code/models/radiator_model';
import { PipeworkDesignPage } from './design/pages/pipework_design_page';
import { PerformanceDesignPage } from './design/pages/performance_design_page';
import { QuoteDesignPage } from './design/pages/quote_design_page';
import { NewFloor, Survey } from './survey/survey';
import { FloorList } from './floor/floor_list';
import { SurveySettings } from './survey/survey_settings';
import { SiteDetails } from './survey/site_details';
import { DefaultMaterialsTab } from './materials/default_materials_tab';
import { Electrics } from './survey/electrics';
import { HeatPumpLocation } from './survey/heat_pump_location';
import { CylinderLocation } from './survey/cylinder_location';
import { CylinderDesignPage } from './design/pages/cylinder_design_page';
import { DEFAULT_PROPERTY_SURVEY, DEFAULT_SURVEY_DESIGN } from '../../code/survey_defaults';
import { getRoleForCompany, hasEnquriesAccess, hasSurveyAccess, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE, USER_ROLE_SUPERADMIN, USER_ROLE_SURVEYOR } from '../../code/models/user';
import { ExistingHeatingSystem } from './survey/existing_heating_system';
import { OfflineBlock } from './particles/offline_block';
import { getSoundAssessmentData } from '../../code/sound_assessment';
import { getEmitterSizeName, getEmitterTypeName, getFullEmittersListByStatus } from '../../code/models/radiator';
import { isFlagSet } from '../../code/helpers';
import { getPerformanceEstimateSummary } from '../../code/models/performance_estimate';
import { findMaxValidFlowTempForScop, findMinValidFlowTempForScop, getHeatPumpCapacityAtOutsideTempAndFlowTemp, getHeatPumpScopAtFlowTemp } from '../../code/models/range_heat_pump';
import { HEATING_FUELS } from '../../code/models/heating_fuel';
import { getCylinderReheatCalculationRow, getDailyHotWaterVolumeL, getHotWaterCalculationRowLegionella, getHotWaterCalculationRowNormalOperation, getNumberOfOccupants } from '../../code/models/hot_water_cylinder';
import { useIndexedDBFallback } from '../../code/use_indexed_db_fallback';
import { OfflinePage } from '../admin/offline_page';
import { HeatLossReport } from './proposal/heatloss_report';
import { getRoomEmitterWatts } from '../../code/models/room';
import { getDesignConditions } from '../../code/models/design_temp';
import { convertLatLngListToLatLongLiteral } from '../../code/geocoding';
import { Pipework } from './survey/pipework';
import { HeatPumpDesign } from './design/pages/heat_pump_design';
import { syncTick } from '../admin/sync';
import { useInterval, useLocalStorage } from 'usehooks-ts';
import { canViewSurvey, createSurveyInvitation, revokeInvitation } from '../../code/models/invitations';
import { UnauthorizedPage } from '../admin/unauthorized_page';
import { Invitations } from '../admin/components/invitations';
import { RequireRole } from '../../require_role';
import { FloorplanFlow } from './survey/floorplan_flow';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { Loader } from '../../components/indicators_and_messaging/loader';
import { getPipeData } from '../../code/models/pipes';
import { estimateAgeBandToSurveyAgeBand } from '../../code/models/age_bands';
import { db } from './db';
import { useLiveQuery } from 'dexie-react-hooks';
import { Button } from '../../components/buttons/button';
import { Alert } from '../../components/indicators_and_messaging/alert';
import { EmptyState } from '../../components/content_display/empty_state';
import { BUSGrantBadge } from '../admin/components/bus_grant_badge';
export const IsOfflineContext = React.createContext(false);
export const PropertyHome = () => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28;
    const navigate = useNavigate();
    const leadUUID = (_a = window.location.pathname.match(/\/surveys\/([a-f0-9-]+)$/)) === null || _a === void 0 ? void 0 : _a[1];
    const companyPublicInfo = useRouteLoaderData('company_root');
    const adminContext = useContext(AdminContext);
    const [page, setPage] = useState();
    const [currentFloorId, setCurrentFloorId] = useState();
    const [designTab, setDesignTab] = useState('');
    const [surveyTab, setSurveyTab] = useState('');
    const [currentRoomId, setCurrentRoomId] = useState();
    const [stageSize, setStageSize] = useState({
        width: 0,
        height: 0
    });
    const [invitations, setInvitations] = useState([]);
    const stageRef = useRef(null);
    const [stageScale, setStageScale] = useState(0.05);
    const [stagePosition, setStagePosition] = useState({ x: stageSize.width / 2, y: stageSize.height / 2 });
    const [isSyncing, setIsSyncing] = useState(false);
    const [lastSynced, setLastSynced] = useLocalStorage('last_synced', 0);
    const [hasSynced, setHasSynced] = useState(false);
    const surveyDB = useLiveQuery(async () => await db.surveys.get(leadUUID));
    const customMaterials = (_b = useLiveQuery(async () => await db.custom_materials.where('company_uuid').equals(companyPublicInfo.uuid).and(x => !x.deleted_at).toArray())) !== null && _b !== void 0 ? _b : [];
    const files = (_c = useLiveQuery(async () => await db.files.where('company_uuid').equals(companyPublicInfo.uuid).and(x => !x.deleted_at).toArray())) !== null && _c !== void 0 ? _c : [];
    const survey = surveyDB ? ensurePropertySurveyBackwardsCompatibility(surveyDB.data) : undefined;
    const setSurvey = async (updateFn) => {
        const surveyOrNew = await db.surveys.get(leadUUID);
        const updatedSurvey = typeof updateFn === 'function' ? updateFn(surveyOrNew.data) : updateFn;
        await db.surveys.update(leadUUID, { data: updatedSurvey, updated_at: new Date().getTime() });
    };
    const addNewSurvey = async () => {
        var _a, _b, _c, _d, _e;
        const newSurvey = { ...DEFAULT_PROPERTY_SURVEY, uuid: leadUUID, designs: [{ ...DEFAULT_SURVEY_DESIGN, uuid: crypto.randomUUID() }] };
        const hydratedFromLead = lead
            ? handleEstimateMapping(lead, adminContext.data.genericMaterials, (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.heatPumps) !== null && _b !== void 0 ? _b : [], (_d = (_c = adminContext.data) === null || _c === void 0 ? void 0 : _c.hotWaterCylinders) !== null && _d !== void 0 ? _d : [], (_e = adminContext.data) === null || _e === void 0 ? void 0 : _e.company, newSurvey, setCustomMaterials)
            : newSurvey;
        await db.surveys.add({
            uuid: leadUUID,
            created_at: new Date().getTime(),
            updated_at: new Date().getTime(),
            deleted_at: undefined,
            server_updated_at: 0,
            data: hydratedFromLead,
            company_uuid: companyPublicInfo.uuid
        });
    };
    const setCustomMaterials = async (updateFn) => {
        const customMaterials = await db.custom_materials.filter(x => x.company_uuid === companyPublicInfo.uuid && !x.deleted_at).toArray();
        const updatedMaterials = typeof updateFn === 'function' ? updateFn(customMaterials) : updateFn;
        await db.custom_materials.bulkPut(updatedMaterials.map(x => ({ key: x.uuid, ...x })));
    };
    const setFiles = async (updateFn) => {
        const files = await db.files.filter(x => x.company_uuid === companyPublicInfo.uuid).toArray();
        const updatedFiles = typeof updateFn === 'function' ? updateFn(files) : updateFn;
        await db.files.bulkPut(updatedFiles.map(x => ({ key: x.uuid, ...x })));
    };
    const [lead] = useIndexedDBFallback('admin_data', 'lead-' + leadUUID, undefined, async () => {
        const apiLead = await getLead(leadUUID, companyPublicInfo.uuid);
        if (!apiLead)
            return;
        // backward compatibility with old leads before we started to store location and recommendations to the lead
        const leadWithPostcodeLocation = await ensureLeadHasLocation3D(apiLead, companyPublicInfo.uuid);
        return await ensureLeadHasEPCRecommendations(leadWithPostcodeLocation, companyPublicInfo.uuid, true);
    }, noop);
    // If the database is ready, check if we have any migrations to run before syncing, if not start syncing.
    useEffect(() => {
        syncTick(lastSynced, setLastSynced, isSyncing, setIsSyncing, setHasSynced);
    }, []);
    useInterval(() => {
        syncTick(lastSynced, setLastSynced, isSyncing, setIsSyncing, setHasSynced);
    }, 10000);
    useEffect(() => {
        var _a, _b, _c, _d;
        setInvitations((_d = (_c = (_b = (_a = adminContext.data) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.invitations) === null || _c === void 0 ? void 0 : _c.sent.filter(x => x.entity_type === 'survey' && x.entity_uuid === leadUUID)) !== null && _d !== void 0 ? _d : []);
    }, [(_f = (_e = (_d = adminContext.data) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.invitations) === null || _f === void 0 ? void 0 : _f.sent]);
    // blockers of rendering in case the data is not loaded yet
    if (adminContext.isLoading || !lead || !hasSynced) {
        return React.createElement("div", { className: 'p-5 flex justify-center' },
            adminContext.isOffline && React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys`, customHeader: 'This survey has not been saved locally.', customMessage: 'If you find internet and open this survey we will save it locally for use offline.' }),
            !adminContext.isOffline && React.createElement(Loader, null));
    }
    if (((_g = adminContext.data) === null || _g === void 0 ? void 0 : _g.user) && !hasSurveyAccess((_h = adminContext.data) === null || _h === void 0 ? void 0 : _h.company, (_j = adminContext.data) === null || _j === void 0 ? void 0 : _j.user))
        return React.createElement("div", { className: 'p-5 flex justify-center' }, "403. Access denied");
    // Migration on exposed floor default (can't go in usual place as need lead to figure out
    if ((survey === null || survey === void 0 ? void 0 : survey.default_materials) && !survey.default_materials.exposedFloor) {
        survey.default_materials.exposedFloor = getExposedFloorMaterial(adminContext.data.genericMaterials, survey.age_band);
    }
    if (!surveyDB || !survey) {
        return React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement("div", { className: 'flex flex-col gap-3 px-5 py-4 border-b border-gray-200' },
                React.createElement("div", { className: 'flex flex-wrap items-center gap-3' },
                    React.createElement("div", { className: 'cursor-pointer flex flex-wrap gap-3 items-center', onClick: () => history.back() },
                        React.createElement(FontAwesomeIcon, { className: 'text-gray-600', icon: faChevronLeft }),
                        React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, (_k = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _k === void 0 ? void 0 : _k.name),
                        React.createElement("div", { className: 'text-sm text-gray-600' }, lead && getAddressIncludingPostcode(lead))))),
            React.createElement(OfflineBlock, null),
            React.createElement(EmptyState, { icon: faList, primaryText: 'Start survey', secondaryText: 'Starting a survey will pull in material, heat pump and other data from your enquiry.', primaryButton: React.createElement(Button, { onClick: addNewSurvey, iconLeft: faPlus }, "Start survey"), alert: React.createElement(Alert, { type: 'WARNING' }, "If you have previously started this survey on another device please wait for the synchronisation to finish or the data will be overwritten by this process.") }));
    }
    const setLocationImages = async (value) => await setSurvey({ ...survey, location_images: value });
    const setCustomRadiatorModels = (updateFn) => {
        setSurvey((prevSurvey) => ({
            ...prevSurvey,
            custom_radiator_models: updateFn(prevSurvey.custom_radiator_models)
        }));
    };
    const customRadiatorModels = survey.custom_radiator_models; // Doing this at top level so can easily swap out for a database read when we move custom radiator models to the company level
    const allRadiatorModels = [...RADIATOR_MODELS, ...customRadiatorModels];
    const setFloors = async (floors) => await setSurvey(prev => ({ ...prev, floors }));
    const setFloor = (floor) => {
        setFloors([...survey.floors.map(x => x.uuid === floor.uuid ? floor : x)]);
    };
    const removeFloor = (id) => {
        const filteredFloors = survey.floors.filter(x => x.uuid !== id);
        setFloors([...filteredFloors]);
        if (filteredFloors.length > 0)
            setCurrentFloorId(filteredFloors[0].uuid);
    };
    const setDesign = async (design) => await setSurvey(prev => ({ ...prev, designs: [design] }));
    const setFlowTemp = async (temp) => await setDesign({ ...design, flow_temp: temp });
    const setQuoteLineItems = async (value) => await setDesign({ ...design, quote_line_items: value });
    const design = survey.designs[0];
    const heatPumps = ((_l = adminContext.data.heatPumps) !== null && _l !== void 0 ? _l : []).filter(x => !x.deleted_at);
    const hydratedHeatPumps = heatPumps.map(x => ({
        ...x,
        range_heat_pump: {
            ...x.range_heat_pump,
            brand_range: adminContext.data.brandRanges.find(y => { var _a; return y.uuid === ((_a = x.range_heat_pump) === null || _a === void 0 ? void 0 : _a.brand_range_uuid); })
        }
    }));
    const currentHeatPump = hydratedHeatPumps.find(x => x.uuid === design.current_heat_pump_uuid);
    const minFlowTemp = findMinValidFlowTempForScop(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump);
    const maxFlowTemp = findMaxValidFlowTempForScop(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump);
    const latLng = convertLatLngListToLatLongLiteral(lead.property.postcodeLocation);
    const altitudetoUseM = (_m = survey.altitude_override_m) !== null && _m !== void 0 ? _m : lead.property.altitudeM;
    const { designTempDefaultC, degreeDays, groundTempC } = getDesignConditions(latLng, altitudetoUseM, survey.exposed_location); // done separately as needed in survey_settings
    const designTempC = (_o = survey.design_temp_override_c) !== null && _o !== void 0 ? _o : designTempDefaultC;
    const currentFloor = survey.floors.find(x => x.uuid === currentFloorId);
    if (currentFloor) {
        return React.createElement(FloorPage, { removeFloor: removeFloor, setCurrentFloorId: setCurrentFloorId, survey: survey, floors: survey.floors, floor: currentFloor, setFloor: setFloor, files: files, setFiles: setFiles, designTempC: designTempC, groundTempC: groundTempC, setSurvey: setSurvey, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, lead: lead, setSurveyTab: setSurveyTab, design: design, currentRoomId: currentRoomId, setCurrentRoomId: setCurrentRoomId, stageScale: stageScale, setStageScale: setStageScale, stagePosition: stagePosition, setStagePosition: setStagePosition, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, currentFloorId: currentFloorId, stageRef: stageRef, stageSize: stageSize, setStageSize: setStageSize, companyUuid: companyPublicInfo.uuid });
    }
    // Inputs
    const hotWaterCylinders = ((_p = adminContext.data.hotWaterCylinders) !== null && _p !== void 0 ? _p : []).filter(x => !x.deleted_at);
    const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === design.current_hot_water_cylinder_uuid);
    // Summary calculations
    const totalHeatLossKw = getTotalHeatLossWatts(survey, designTempC, groundTempC) / 1000;
    // calculate demandMet by room groups, not independent rooms.
    // because some rooms are gaining heat from the other rooms in the group
    const roomGroupsHeatDemandMet = chain(survey.floors.flatMap(x => x.rooms.map(y => ({ ...y, floor: x }))))
        .groupBy(x => x.room_group_uuid ? x.room_group_uuid : x.uuid)
        .map((values, key) => {
        const roomGroupRadiatorWatts = sum(values.map(r => getRoomEmitterWatts(r, design, survey, designTempC, groundTempC)));
        const roomGroupRoomWatts = sum(values.map(r => getRoomWatts(r, r.floor.rooms, designTempC, groundTempC, survey)));
        const status = roomGroupRadiatorWatts > roomGroupRoomWatts ? 'Sufficient' : design.undersized_emitter_rooms.some(x => x.room_uuid === key) ? 'Accepted' : 'Insufficient';
        return { roomGroupKey: key, status };
    })
        .value();
    const emitterDemandStatus = roomGroupsHeatDemandMet.every(x => x.status === 'Sufficient')
        ? 'Sufficient' // sufficient if all marked sufficient
        //   Insufficient if any marked insufficient
        : roomGroupsHeatDemandMet.some(x => x.status === 'Insufficient')
            ? 'Insufficient'
            : 'Accepted'; // accepted if not all sufficient and none insufficient
    // Heat pump calculations
    const scopSpaceHeating = getHeatPumpScopAtFlowTemp(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, design.flow_temp);
    const soundAssessmentData = getSoundAssessmentData(survey.sound_barrier_uuid, survey.reflective_surfaces, survey.sound_distance, (_q = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _q === void 0 ? void 0 : _q.sound_power_max_dba);
    const heatPumpCapacityResult = getHeatPumpCapacityAtOutsideTempAndFlowTemp(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, designTempC, design.flow_temp);
    const soundAssessment = currentHeatPump ? (_r = soundAssessmentData === null || soundAssessmentData === void 0 ? void 0 : soundAssessmentData.finalResultDba) !== null && _r !== void 0 ? _r : 0 : 0;
    // Radiator calculations
    const roomOrRoomGroups = survey.floors.flatMap(x => x.rooms.map(y => ({ uuid: y.uuid, group_uuid: y.room_group_uuid })));
    const addedOrReplacedRadiators = design.radiators
        .filter(x => roomOrRoomGroups.some(y => y.uuid === x.room_uuid || y.group_uuid === x.room_uuid))
        .map(r => {
        var _a;
        return ({
            radiator_type_uuid: r.emitter_type === 'RADIATOR' ? (_a = r.radiator_type) === null || _a === void 0 ? void 0 : _a.uuid : undefined,
            name: getEmitterTypeName(r),
            dimensions: getEmitterSizeName(r),
            badge: getRadiatorBadge(r, false, design.removed_radiator_uuids, false)
        });
    });
    const emitterRows = [...addedOrReplacedRadiators];
    const totalNewRadiators = addedOrReplacedRadiators.length;
    const radiatorPrice = 600; // TODO: How do we set radiator prices?
    const emitterColumns = [
        { name: 'Item', render: (row) => React.createElement("div", null, row.name) },
        { name: 'Dimensions', render: (row) => React.createElement("div", null, row.dimensions) },
        { name: 'Badge', render: (row) => React.createElement("div", { className: 'flex justify-end' }, row.badge) }
    ];
    // Hot water calculations
    const cylinderReheatRow = getCylinderReheatCalculationRow(currentHotWaterCylinder, design.hot_water_storage_temperature_c, currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, designTempC);
    const numberOfOccupants = getNumberOfOccupants(survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l);
    const hotWaterRowNormal = getHotWaterCalculationRowNormalOperation(currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump, dailyHotWaterVolumeL, design.hot_water_storage_temperature_c);
    const hotWaterRowLegionella = getHotWaterCalculationRowLegionella(design.hot_water_storage_temperature_c, (_s = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.litres) !== null && _s !== void 0 ? _s : 0, survey.legionnaires_cycle_weeks);
    const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear;
    const scopHotWater = hotWaterRowNormal.heatingEfficiency;
    // Performance calculations
    // Ignore legionella for now in HDD based performance estimate - add back in when use a less conservative efficiency
    const designHotWaterDemandKwh = hotWaterRowNormal.heatEnergyKwhPerCycle * hotWaterRowNormal.cyclesPerYear;
    const heatLossWattsPerKelvin = getHeatTransferCoefficientWattsPerKelvin(survey, designTempC, groundTempC);
    const eligibleForHeatPumpPlus = ((_u = (_t = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.range_heat_pump) === null || _t === void 0 ? void 0 : _t.brand_range) === null || _u === void 0 ? void 0 : _u.brand) ? ['Vaillant', 'Viessman', 'Mitsubishi'].includes((_w = (_v = currentHeatPump.range_heat_pump) === null || _v === void 0 ? void 0 : _v.brand_range) === null || _w === void 0 ? void 0 : _w.brand) : false;
    const performanceEstimateSummary = getPerformanceEstimateSummary(survey, heatLossWattsPerKelvin, degreeDays, designHotWaterDemandKwh, scopHotWater, scopSpaceHeating, eligibleForHeatPumpPlus);
    // Use HDD based values for bill estimates for now - allow user to choose later
    const annualBillEstimateGBP = performanceEstimateSummary.hddEstimate.proposed.costPriceCap;
    const performanceEstimateColumns = [
        {
            key: 'name',
            name: 'Name',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.name) : React.createElement("div", null, row.name))
        },
        {
            key: 'kwh',
            name: 'Energy',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.kwh) : React.createElement("div", null, row.kwh))
        },
        {
            key: 'costUserEnteredTariff', // render with the user entered tariff so they can play. In report this won't be show, the other 3 options will be
            name: 'Bills',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.costUserEnteredTariff) : React.createElement("div", null, row.costUserEnteredTariff))
        },
        {
            key: 'emissionsKG',
            name: 'Emissions',
            render: (row) => React.createElement(React.Fragment, null,
                " ",
                row.name === 'Savings' ? React.createElement("div", { className: 'font-bold' }, row.emissionsKG) : React.createElement("div", null, row.emissionsKG))
        }
    ];
    // Quote calculations
    const lineItemColumns = [
        { key: 'name', name: 'Item' },
        { key: 'quantity', name: 'Qty' },
        { key: 'value', name: 'Value', render: (row) => React.createElement("div", { className: 'text-gray-600' },
                "\u00A3",
                row.value) }
    ];
    const defaultLineItems = [
        {
            uuid: undefined,
            name: (_x = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name) !== null && _x !== void 0 ? _x : '',
            quantity: 1,
            value: (_y = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.price) !== null && _y !== void 0 ? _y : 0,
            type: 'HEAT_PUMP'
        },
        {
            uuid: undefined,
            name: (_z = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.name) !== null && _z !== void 0 ? _z : '',
            quantity: 1,
            value: (_0 = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.price) !== null && _0 !== void 0 ? _0 : 0,
            type: 'CYLINDER'
        },
        { uuid: undefined, name: 'Parts', quantity: 1, value: (_1 = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.parts) !== null && _1 !== void 0 ? _1 : 0, type: 'PARTS' },
        { uuid: undefined, name: 'Labour', quantity: 1, value: (_2 = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.price) !== null && _2 !== void 0 ? _2 : 0, type: 'LABOUR' },
        { uuid: undefined, name: 'Radiators', quantity: totalNewRadiators, value: radiatorPrice, type: 'RADIATORS' }
    ];
    const hydratedLineItems = [...defaultLineItems.map(dli => {
            const override = design.quote_line_items.find(li => li.type === dli.type);
            return override || dli;
        }), ...design.quote_line_items.filter(x => x.type === 'ADDITIONAL')];
    const totalCost = sum(hydratedLineItems.map(x => x.value * x.quantity));
    // Pipework calculations
    const designedEmitters = getFullEmittersListByStatus(survey, design)
        .filter(x => x.status !== 'REMOVED' && x.radiator.type !== 'SECONDARY')
        .map(x => ({ ...(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator), room: x.room, watts: getEmitterWatts(x.status === 'REPLACED' && x.replacedBy ? x.replacedBy : x.radiator, x.room, design, survey, designTempC, groundTempC) }));
    // Get the index emitter. If the survey's index emitter has been replaced then set that replacement emitter as the index emitter apart from if an override has been set in the design (this means as soon as the user changes it in design the link to the survey is lost)
    const indexEmitterReplacementUUID = (_3 = designedEmitters.find(x => x.type === 'DESIGN' && x.replaces_uuid === survey.index_emitter_uuid)) === null || _3 === void 0 ? void 0 : _3.uuid;
    const indexEmitterUUID = (_5 = (_4 = design.index_emitter_uuid_override) !== null && _4 !== void 0 ? _4 : indexEmitterReplacementUUID) !== null && _5 !== void 0 ? _5 : survey.index_emitter_uuid;
    // Get the secondary emitters. If the survey's secondary index emitters have been replaced then set those replacement emitters as the secondary emitters
    const secondaryEmitterUUIDsThroughReplacement = designedEmitters.filter(x => x.type === 'DESIGN' && x.replaces_uuid && survey.secondary_index_emitter_uuids.includes(x.replaces_uuid)).map(x => x.uuid);
    const secondaryEmitterUUIDSAll = [
        ...survey.secondary_index_emitter_uuids.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // remaining survey emitters that were on the survey index circuit
        ...secondaryEmitterUUIDsThroughReplacement.filter(x => !design.removed_secondary_index_emitter_uuids.includes(x)), // survey emitters that were on the survey index circuit but have been replaced
        ...design.secondary_index_emitter_uuids // emitters that have been added on the design side
    ].filter(x => designedEmitters.some(y => y.uuid === x)); // only include if it's in the designed emitters list (normally selecting from design rads so not strictly needed, but clearer this way)
    const secondaryEmitterUUIDS = Array.from(new Set(secondaryEmitterUUIDSAll)); // remove duplicates - unlikely to occur but could if you added a survey to the design list of emitters and then added it on the survey side
    const pipeDataList = getPipeData(survey, design, design.delta_t_flow_return_c, design.flow_temp, heatPumpCapacityResult.capacityKw, designedEmitters, indexEmitterUUID, secondaryEmitterUUIDS);
    // Survey & Design pages
    if (surveyTab === 'FLOOR_LIST') {
        return React.createElement(FloorList, { setFlowTemp: setFlowTemp, floors: survey.floors, setSurveyTab: setSurveyTab, setFloorId: setCurrentFloorId, designTempC: designTempC, groundTempC: groundTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, design: design, setCurrentRoomId: setCurrentRoomId, setSurvey: setSurvey });
    }
    if (surveyTab === 'NEW_FLOOR') {
        return React.createElement(NewFloor, { survey: survey, setSurveyTab: setSurveyTab, setSurvey: setSurvey, setCurrentFloorId: setCurrentFloorId, floors: survey.floors });
    }
    if (surveyTab === 'SETTINGS') {
        return React.createElement(SurveySettings, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, designTempDefault: designTempDefaultC, altitudeDefaultM: (_6 = lead.property.altitudeM) !== null && _6 !== void 0 ? _6 : 0 });
    }
    if (surveyTab === 'FLOORPLAN_FLOW') {
        return React.createElement(FloorplanFlow, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, designTempDefault: designTempDefaultC, altitudeDefaultM: (_7 = lead.property.altitudeM) !== null && _7 !== void 0 ? _7 : 0, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, setCurrentFloorId: setCurrentFloorId, companyUUID: companyPublicInfo.uuid });
    }
    if (surveyTab === 'PROPERTY') {
        return React.createElement(SiteDetails, { lead: lead, postcodeLocation: latLng, survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, files: files, setFiles: setFiles, companyUUID: companyPublicInfo.uuid });
    }
    if (surveyTab === 'EXISTING_HEATING') {
        return React.createElement(ExistingHeatingSystem, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab, files: files, setFiles: setFiles, companyUUID: companyPublicInfo.uuid });
    }
    if (surveyTab === 'MATERIALS') {
        return React.createElement(DefaultMaterialsTab, { setSurveyTab: setSurveyTab, materials: [...adminContext.data.genericMaterials, ...customMaterials], setMaterials: setCustomMaterials, materialsLayers: adminContext.data.materialsGenericLayers, setSurvey: setSurvey, survey: survey, companyUUID: companyPublicInfo.uuid });
    }
    if (surveyTab === 'ELECTRICS') {
        return React.createElement(Electrics, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: companyPublicInfo.uuid });
    }
    if (surveyTab === 'PIPEWORK') {
        return React.createElement(Pipework, { survey: survey, setSurvey: setSurvey, setSurveyTab: setSurveyTab });
    }
    if (surveyTab === 'HEAT_PUMP') {
        return React.createElement(HeatPumpLocation, { setSurveyTab: setSurveyTab, survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: hydratedHeatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, flowTempC: design.flow_temp, companyUUID: companyPublicInfo.uuid, lead: lead });
    }
    if (designTab === 'HEAT_PUMP') {
        return React.createElement(HeatPumpDesign, { setDesignTab: setDesignTab, survey: survey, design: design, currentHeatPump: currentHeatPump, setSurvey: setSurvey, setDesign: setDesign, heatPumps: hydratedHeatPumps, files: files, setFiles: setFiles, locationImages: survey.location_images, setLocationImages: setLocationImages, soundCalculation: soundAssessment, designTempC: designTempC, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, scop: scopSpaceHeating, totalHeatLossKW: totalHeatLossKw, companyUUID: companyPublicInfo.uuid });
    }
    if (designTab === 'EMITTER') {
        return React.createElement(EmitterDesignPage, { setDesignTab: setDesignTab, customRadiatorModels: customRadiatorModels, setCustomRadiatorModels: setCustomRadiatorModels, allRadiatorModels: allRadiatorModels, floors: survey.floors, setFloor: setFloor, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, designTempC: designTempC, groundTempC: groundTempC, survey: survey, design: design, setDesign: setDesign, annualBillEstimateGBP: annualBillEstimateGBP, files: files, emitterDemandStatus: emitterDemandStatus, totalHeatLossKw: totalHeatLossKw, companyUuid: companyPublicInfo.uuid, setFiles: setFiles, setSurvey: setSurvey });
    }
    if (surveyTab === 'CYLINDER') {
        return React.createElement(CylinderLocation, { setSurveyTab: setSurveyTab, survey: survey, setSurvey: setSurvey, files: files, setFiles: setFiles, companyUUID: companyPublicInfo.uuid });
    }
    if (designTab === 'CYLINDER') {
        return React.createElement(CylinderDesignPage, { files: files, setFiles: setFiles, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, survey: survey, setSurvey: setSurvey, cylinderReheatRow: cylinderReheatRow, hotWaterRowNormal: hotWaterRowNormal, hotWaterRowLegionella: hotWaterRowLegionella, design: design, setDesign: setDesign, setDesignTab: setDesignTab, hotWaterCylinders: hotWaterCylinders, currentHotWaterCylinderId: design.current_hot_water_cylinder_uuid, companyUUID: companyPublicInfo.uuid });
    }
    if (designTab === 'PIPEWORK') {
        return React.createElement(PipeworkDesignPage, { setDesignTab: setDesignTab, flowTempC: design.flow_temp, deltaTFlowReturnC: design.delta_t_flow_return_c, design: design, setDesign: setDesign, survey: survey, pipeDataList: pipeDataList, designedEmitters: designedEmitters, indexEmitterUUID: indexEmitterUUID, secondaryEmitterUUIDS: secondaryEmitterUUIDS, secondaryEmitterUUIDsThroughReplacement: secondaryEmitterUUIDsThroughReplacement });
    }
    if (designTab === 'PERFORMANCE' && lead) {
        return React.createElement(PerformanceDesignPage, { survey: survey, setSurvey: setSurvey, postcode: lead.property.postcode, setDesignTab: setDesignTab, performanceEstimateColumns: performanceEstimateColumns, performanceEstimateSummary: performanceEstimateSummary, degreeDays: degreeDays, heatLossWattsPerKelvin: heatLossWattsPerKelvin, scopSpaceHeating: scopSpaceHeating, scopHotWater: scopHotWater });
    }
    if (designTab === 'QUOTE') {
        return React.createElement(QuoteDesignPage, { setDesignTab: setDesignTab, defaultLineItems: defaultLineItems, lineItems: design.quote_line_items, setLineItems: setQuoteLineItems });
    }
    if (page === 'SURVEY') {
        return React.createElement(Survey, { lead: lead, survey: survey, setSurvey: setSurvey, surveyTab: surveyTab, setSurveyTab: setSurveyTab, setCurrentFloorId: setCurrentFloorId, setPage: setPage });
    }
    if (page === 'DESIGN') {
        return React.createElement(DesignHome, { design: design, setDesign: setDesign, setDesignTab: setDesignTab, soundAssessment: soundAssessment, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, heatPumpCapacityResult: heatPumpCapacityResult, totalHeatLossKwSurvey: totalHeatLossKw, totalCost: totalCost, annualBillEstimateGBP: annualBillEstimateGBP, reheatTimeHoursAndMinutes: (_8 = cylinderReheatRow === null || cylinderReheatRow === void 0 ? void 0 : cylinderReheatRow.reheatTimeHoursAndMinutes) !== null && _8 !== void 0 ? _8 : '', hotWaterConsumptionAnnual: hotWaterConsumptionAnnual, performanceEstimateColumns: performanceEstimateColumns, hddPerformanceEstimate: performanceEstimateSummary.hddEstimate, emitterColumns: emitterColumns, emitterRows: emitterRows, scop: scopSpaceHeating, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp, lineItemColumns: lineItemColumns, hydratedLineItems: hydratedLineItems, setPage: setPage, emitterDemandStatus: emitterDemandStatus, pipeData: pipeDataList });
    }
    if (page === 'HEATLOSS_REPORT') {
        return React.createElement(HeatLossReport, { survey: survey, design: design, lead: lead, company: adminContext.data.company, soundAssessment: soundAssessment, designTempC: designTempC, groundTempC: groundTempC, degreeDays: degreeDays, currentHeatPump: currentHeatPump, currentHotWaterCylinder: currentHotWaterCylinder, cylinderReheatRow: cylinderReheatRow, hotWaterRowNormal: hotWaterRowNormal, hotWaterRowLegionella: hotWaterRowLegionella, performanceEstimateSummary: performanceEstimateSummary, performanceEstimateColumns: performanceEstimateColumns, eligibleForHeatPumpPlus: eligibleForHeatPumpPlus, files: files, setPage: setPage });
    }
    // Base Page
    const { totalWatts, heatPump: leadHeatPump, hotWaterCylinder: leadHotWaterCylinder, totalPrice, busGrantEligibleReasons, hesGrantEligibleReasons } = calculateEstimate(lead, (_10 = (_9 = adminContext.data) === null || _9 === void 0 ? void 0 : _9.heatPumps) !== null && _10 !== void 0 ? _10 : [], (_12 = (_11 = adminContext.data) === null || _11 === void 0 ? void 0 : _11.hotWaterCylinders) !== null && _12 !== void 0 ? _12 : [], (_13 = adminContext.data) === null || _13 === void 0 ? void 0 : _13.company, undefined, undefined);
    const notGrantEligible = (!lead.epc_scotland && (!((_14 = lead.epcData) === null || _14 === void 0 ? void 0 : _14.lmkKey) || busGrantEligibleReasons.some(x => !x.hasPassed)));
    const isScottish = checkIfScottish(lead.property.postcode);
    const notHesGrantEligible = isScottish && hesGrantEligibleReasons.some(x => !x.hasPassed);
    const estimateValues = [
        { key: 'Heat loss', value: `${(totalWatts / 1000).toFixed(2)} kW` },
        { key: 'Heat pump', value: leadHeatPump === null || leadHeatPump === void 0 ? void 0 : leadHeatPump.name },
        { key: 'Cylinder', value: leadHotWaterCylinder === null || leadHotWaterCylinder === void 0 ? void 0 : leadHotWaterCylinder.name },
        {
            key: 'BUS Grant',
            value: React.createElement("div", { className: 'flex justify-end' },
                React.createElement(BUSGrantBadge, { isScottish: isScottish, notGrantEligible: notGrantEligible, notHesGrantEligible: notHesGrantEligible, lead: lead }))
        },
        { key: 'Price (post grant)', value: formatPrice(totalPrice) }
    ];
    const surveyValues = [
        { key: 'Heat loss', value: `${totalHeatLossKw.toFixed(2)} kW` },
        { key: 'Heat pump location', value: !survey.location_description || survey.location_description.length === 0 ? 'N/A' : survey.location_description, parentClass: 'truncate' },
        { key: 'Sound assessment', value: React.createElement("div", { className: 'flex justify-end' },
                React.createElement(Badge, { color: soundAssessment === 0 ? 'YELLOW' : soundAssessment <= 42 ? 'GREEN' : 'RED', text: soundAssessment === 0 ? 'Incomplete' : soundAssessment <= 42 ? 'Pass' : 'Fail' })), parentClass: '' },
        { key: 'Cylinder location', value: !survey.cylinder_location_description || survey.location_description.length === 0 ? 'N/A' : survey.cylinder_location_description, parentClass: 'truncate' }
    ];
    const designValues = [
        { key: 'Heat pump', value: (_15 = currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name) !== null && _15 !== void 0 ? _15 : 'N/A' },
        { key: 'Cylinder', value: (_16 = currentHotWaterCylinder === null || currentHotWaterCylinder === void 0 ? void 0 : currentHotWaterCylinder.name) !== null && _16 !== void 0 ? _16 : 'N/A' },
        { key: 'Flow temperature', value: design.flow_temp ? `${design.flow_temp} °C` : 'N/A' },
        { key: 'Radiator changes', value: totalNewRadiators }
    ];
    // If the user is a surveyor and doesn't have access to this specific survey, show error page
    const userRole = getRoleForCompany((_17 = adminContext.data) === null || _17 === void 0 ? void 0 : _17.user, (_19 = (_18 = adminContext.data) === null || _18 === void 0 ? void 0 : _18.company) === null || _19 === void 0 ? void 0 : _19.public_info.subdomain);
    if (userRole === USER_ROLE_SURVEYOR && !canViewSurvey({ user: (_20 = adminContext.data) === null || _20 === void 0 ? void 0 : _20.user, leadUUID: survey.uuid, companySubdomain: (_22 = (_21 = adminContext.data) === null || _21 === void 0 ? void 0 : _21.company) === null || _22 === void 0 ? void 0 : _22.public_info.subdomain })) {
        return React.createElement(UnauthorizedPage, null);
    }
    const sendInvitation = async (email) => {
        if (!email)
            return;
        const invitation = await createSurveyInvitation({
            email,
            companyUuid: companyPublicInfo.uuid,
            surveyUuid: leadUUID
        });
        if (invitation) {
            setInvitations([...invitations, invitation]);
        }
    };
    const revokeSurveyInvitation = async (uuid) => {
        const success = await revokeInvitation(uuid);
        if (success) {
            setInvitations(invitations.filter(x => x.uuid !== uuid));
        }
    };
    return (React.createElement(RequireRole, { roles: [USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SURVEYOR] },
        React.createElement("div", { className: 'flex flex-col h-full' },
            React.createElement("div", { className: 'flex flex-col gap-3 px-5 py-4 border-b border-gray-200' },
                React.createElement("div", { className: 'flex flex-wrap items-center gap-3' },
                    React.createElement("div", { className: 'cursor-pointer flex flex-wrap gap-3 items-center', onClick: () => history.back() },
                        React.createElement(FontAwesomeIcon, { className: 'text-gray-600', icon: faChevronLeft }),
                        React.createElement("div", { className: 'text-gray-900 text-xl font-bold' }, (_23 = lead === null || lead === void 0 ? void 0 : lead.customer) === null || _23 === void 0 ? void 0 : _23.name),
                        React.createElement("div", { className: 'text-sm text-gray-600' }, lead && getAddressIncludingPostcode(lead))))),
            React.createElement(OfflineBlock, null),
            React.createElement("div", { className: "p-5 bg-white flex-col gap-4 flex" },
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Estimate', onClick: () => navigate(`/${companyPublicInfo.subdomain}/admin/quotes/${lead.uuid}`), disabled: !hasEnquriesAccess((_24 = adminContext.data) === null || _24 === void 0 ? void 0 : _24.company, (_25 = adminContext.data) === null || _25 === void 0 ? void 0 : _25.user) },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, estimateValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: "text-xs flex-1 font-semibold text-end" }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Survey', onClick: () => setPage('SURVEY') },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, surveyValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: `text-xs flex-1 font-semibold text-end ${x.parentClass}` }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Design', onClick: () => setPage('DESIGN') },
                    React.createElement("div", { className: "flex-col flex divide-y divide-dashed divide-gray-200" }, designValues.map(x => React.createElement("div", { key: x.key, className: 'flex justify-between py-2 items-center gap-2' },
                        React.createElement("div", { className: "text-xs flex-1 font-semibold" }, x.key),
                        React.createElement("div", { className: "text-xs flex-1 font-semibold text-end" }, x.value))))),
                React.createElement(ClickableCard, { variant: 'GREY', title: 'Report', onClick: () => setPage('HEATLOSS_REPORT') }),
                [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SUPERADMIN].includes(getRoleForCompany((_26 = adminContext === null || adminContext === void 0 ? void 0 : adminContext.data) === null || _26 === void 0 ? void 0 : _26.user, (_28 = (_27 = adminContext === null || adminContext === void 0 ? void 0 : adminContext.data) === null || _27 === void 0 ? void 0 : _27.company) === null || _28 === void 0 ? void 0 : _28.public_info.subdomain)) &&
                    React.createElement(Invitations, { invitations: invitations, handleSendInvitation: sendInvitation, handleRevokeInvitation: revokeSurveyInvitation, title: 'Invite someone to this survey', helpText: 'People you invite to a survey will only have access to that survey, and will not be able to see your enquiries, costs, or inventory.' })))));
};
const handleEstimateMapping = (lead, genericMaterials, heatPumps, hotWaterCylinders, company, survey, setCustomMaterials) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0;
    let newSurvey = { ...survey };
    // MATERIALS
    // if survey does not have default materials, we need to provide them
    // usually applicable to the first time survey creation
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_MATERIALS)) {
        const mappedMaterials = mapEstimateMaterials(lead, genericMaterials, company.public_info.uuid);
        instantiateMaterialSet(mappedMaterials, setCustomMaterials);
        newSurvey = {
            ...newSurvey,
            default_materials: mappedMaterials,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_MATERIALS
        };
    }
    // AGE BAND
    // check either the age_band is set OR has the EstimateAgeBand type (the `id` attribute is only available in the SurveyAgeBand type)
    // in both cases we need to set the age_band attribute from the EPC or the lead (the method chooses it)
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_AGE_BAND)) {
        newSurvey = {
            ...newSurvey,
            age_band: estimateAgeBandToSurveyAgeBand(lead),
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_AGE_BAND
        };
    }
    // HEATING SYSTEM
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_FUEL_TYPE)) {
        const estimateFuelType = ((_a = lead.property.houseOverrides) === null || _a === void 0 ? void 0 : _a.fuelType) ? lead.property.houseOverrides.fuelType : lead.property.fuelType;
        let mappedUUID;
        switch (estimateFuelType.toLowerCase()) {
            case 'mains gas':
                mappedUUID = (_b = HEATING_FUELS.find(x => x.uuid === 'mains_gas')) === null || _b === void 0 ? void 0 : _b.uuid;
                break;
            case 'oil':
                mappedUUID = (_c = HEATING_FUELS.find(x => x.uuid === 'oil')) === null || _c === void 0 ? void 0 : _c.uuid;
                break;
            case 'lpg':
                mappedUUID = (_d = HEATING_FUELS.find(x => x.uuid === 'lpg')) === null || _d === void 0 ? void 0 : _d.uuid;
                break;
            case 'electric':
                mappedUUID = (_e = HEATING_FUELS.find(x => x.uuid === 'electricity')) === null || _e === void 0 ? void 0 : _e.uuid;
                break;
            case 'other':
                // Map other to mains gas for now
                mappedUUID = (_f = HEATING_FUELS.find(x => x.uuid === 'mains_gas')) === null || _f === void 0 ? void 0 : _f.uuid;
                break;
        }
        newSurvey = {
            ...newSurvey,
            existing_system_fuel_uuid: mappedUUID !== null && mappedUUID !== void 0 ? mappedUUID : '',
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_FUEL_TYPE
        };
    }
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP) ||
        !isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
        // Calculate heat pump and cylinder size based on lead data as is.
        // One time mapping so that the heat loss survey being incomplete doesn't result in us suggesting tiny heat pumps
        const { heatPump: leadHeatPump, hotWaterCylinder: leadHotWaterCylinder } = calculateEstimate(lead, heatPumps, hotWaterCylinders, company, undefined, undefined);
        // heat pump uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HEAT_PUMP)) {
            const design = newSurvey.designs[0];
            design.current_heat_pump_uuid = leadHeatPump === null || leadHeatPump === void 0 ? void 0 : leadHeatPump.uuid;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_HEAT_PUMP
            };
        }
        // flow temp
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_FLOW_TEMP_C)) {
            const design = newSurvey.designs[0];
            design.flow_temp = (_h = (_g = lead.flow_temperature_c) !== null && _g !== void 0 ? _g : company === null || company === void 0 ? void 0 : company.default_flow_temp_c) !== null && _h !== void 0 ? _h : DEFAULT_FLOW_TEMP_C;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_FLOW_TEMP_C
            };
        }
        // hot water cylinder uuid
        if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_HOT_WATER_CYLINDER)) {
            const design = newSurvey.designs[0];
            design.current_hot_water_cylinder_uuid = leadHotWaterCylinder === null || leadHotWaterCylinder === void 0 ? void 0 : leadHotWaterCylinder.uuid;
            newSurvey = {
                ...newSurvey,
                designs: [design],
                flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_HOT_WATER_CYLINDER
            };
        }
    }
    // quote line items
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_QUOTE_LINE_ITEMS)) {
        // The design's quote_line_items attribute should be empty here, so we just override it with our mapped values
        // It means, if there was something in the quote_line_items, it will be removed
        const items = [];
        (_j = lead.lead_line_items) === null || _j === void 0 ? void 0 : _j.forEach(x => {
            items.push({
                uuid: undefined,
                name: x.name,
                quantity: 1,
                value: x.value,
                type: 'ADDITIONAL'
            });
        });
        const design = newSurvey.designs[0];
        if (!design.quote_line_items)
            design.quote_line_items = [];
        design.quote_line_items = items;
        newSurvey = {
            ...newSurvey,
            designs: [design],
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_QUOTE_LINE_ITEMS
        };
    }
    // design outdoor temp — override if the design temp is set explicitly,
    // otherwise use a default value which is provided separately in the UI
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_DESIGN_TEMP)) {
        newSurvey = {
            ...newSurvey,
            design_temp_override_c: ((_l = (_k = lead.property) === null || _k === void 0 ? void 0 : _k.houseOverrides) === null || _l === void 0 ? void 0 : _l.designTempOverride) ? (_o = (_m = lead.property) === null || _m === void 0 ? void 0 : _m.houseOverrides) === null || _o === void 0 ? void 0 : _o.designTempOverride : newSurvey.design_temp_override_c,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_DESIGN_TEMP
        };
    }
    // indoor temp — override if the indoor temp is set explicitly
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_INDOOR_TEMP)) {
        newSurvey = {
            ...newSurvey,
            indoor_temp_overall_c: ((_q = (_p = lead.property) === null || _p === void 0 ? void 0 : _p.houseOverrides) === null || _q === void 0 ? void 0 : _q.internalTempOverride) ? (_s = (_r = lead.property) === null || _r === void 0 ? void 0 : _r.houseOverrides) === null || _s === void 0 ? void 0 : _s.internalTempOverride : newSurvey.indoor_temp_overall_c,
            use_cibse_indoor_temps: !((_u = (_t = lead.property) === null || _t === void 0 ? void 0 : _t.houseOverrides) === null || _u === void 0 ? void 0 : _u.internalTempOverride), // if the override is set, we don't use the CIBSE values
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_INDOOR_TEMP
        };
    }
    // ACH
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_ACH)) {
        newSurvey = {
            ...newSurvey,
            air_change_per_hour_overall: ((_w = (_v = lead.property) === null || _v === void 0 ? void 0 : _v.houseOverrides) === null || _w === void 0 ? void 0 : _w.airChangeOverride) ? (_y = (_x = lead.property) === null || _x === void 0 ? void 0 : _x.houseOverrides) === null || _y === void 0 ? void 0 : _y.airChangeOverride : newSurvey.air_change_per_hour_overall,
            use_cibse_air_change_values: !((_0 = (_z = lead.property) === null || _z === void 0 ? void 0 : _z.houseOverrides) === null || _0 === void 0 ? void 0 : _0.airChangeOverride), // if the override is set, we don't use the CIBSE values
            air_change_year_uuid: newSurvey.age_band ? newSurvey.age_band.ach_age_key : newSurvey.air_change_year_uuid,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_ACH
        };
    }
    // Property type
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_PROPERTY_TYPE)) {
        newSurvey = {
            ...newSurvey,
            property_type: lead.property.propertyType,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_PROPERTY_TYPE
        };
    }
    // Built form
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BUILT_FORM)) {
        newSurvey = {
            ...newSurvey,
            built_form: lead.property.builtForm,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_BUILT_FORM
        };
    }
    // Bedrooms and bathroooms
    if (!isFlagSet(survey.flags_estimate_mappings, ESTIMATE_MAPPED_BEDROOMS_BATHROOMS)) {
        newSurvey = {
            ...newSurvey,
            bedrooms: lead.property.noBedrooms,
            bathrooms: lead.property.noBathrooms,
            flags_estimate_mappings: newSurvey.flags_estimate_mappings | ESTIMATE_MAPPED_BEDROOMS_BATHROOMS
        };
    }
    return newSurvey;
    // OTHER ATTRIBUTES: not mapping them because there is no UI to change them in the Survey. Once we have the UI, we can add the mapping for them.
    // ~Address~ — taken from the Lead in the UI
    // ~total floor area~ — taken from the Lead (lead?.property.floorArea) in the UI
};
