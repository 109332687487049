import React from 'react';
import { EstimateCoverNoteTemplateBlock } from '../estimate_cover_note_template_block';
import { updateCompanySettings } from '../../../../code/models/company';
import { SettingsLayout } from '../components/settings_layout';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Toggle } from '../../../../components/inputs_and_selections/toggle';
import { Section } from '../../../../components/containers/section';
import { HorizontalFormGroup } from '../../../../components/inputs_and_selections/horizontal_form_group';
import { ClickableCard } from '../../../../components/content_display/card';
import { Info } from '../../../../components/buttons/info';
export const EstimateResultsPage = ({ company, setCompany }) => {
    const handleResponsesToggle = async (toggleName) => {
        const otherToggleName = toggleName === 'no_request_callback' ? 'no_survey_booking' : 'no_request_callback';
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                // Flip the current toggle value
                [toggleName]: !company.public_info[toggleName],
                /*
                  Ensure 'no_request_callback' and 'no_survey_booking' can't be true at the same time:
                  - If toggleName is being set to true (currently false), and the otherToggleName is already true,
                  we flip otherToggleName to be false.
                  - Otherwise, leave it as is
                */
                [otherToggleName]: !company.public_info[toggleName] && company.public_info[otherToggleName]
                    ? false : company.public_info[otherToggleName]
            }
        };
        setCompany(newCompany);
        await updateCompanySettings(newCompany);
    };
    return React.createElement(SettingsLayout, { heading: 'Results page', description: 'Customise the content displayed on your estimates' },
        React.createElement(Section, { border: true, title: 'Configuration' },
            React.createElement("div", { className: 'flex flex-col' },
                React.createElement("p", { className: 'pb-6' }, "Choose which sections you would like to appear in the homeowner-facing estimate."),
                React.createElement("div", { className: 'flex flex-col gap-6' },
                    React.createElement(ClickableCard, { variant: 'GREY' },
                        React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Line-by-line cost breakdown', helperText: 'Show the cost of each line item on the estimate' }), input: React.createElement(Toggle, { value: company.public_info.show_line_item_costs_on_estimate, setValue: async () => {
                                    const newCompany = {
                                        ...company,
                                        public_info: {
                                            ...company.public_info,
                                            show_line_item_costs_on_estimate: !company.public_info.show_line_item_costs_on_estimate
                                        }
                                    };
                                    setCompany(newCompany);
                                    await updateCompanySettings(newCompany);
                                } }) })),
                    React.createElement(ClickableCard, { variant: 'GREY' },
                        React.createElement(FormLabel, { labelText: 'Call to action options', info: React.createElement(Info, { infoModalHeader: 'Call to Action Options', infoModalBody: `Give customers the option to book a survey, request a callback or both. You must select at least one option. 
                  For Scottish properties, these options will have no effect and requesting a callback will be enabled by default.` }) }),
                        React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: '\'Book a survey\' button', helperText: 'Display the \'Book a survey\' button on the estimate' }), input: React.createElement(Toggle, { value: !company.public_info.no_survey_booking, setValue: () => handleResponsesToggle('no_survey_booking') }) }),
                        React.createElement(HorizontalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: '\'Request a callback\' button', helperText: 'Display the \'Request a callback\' button on the estimate' }), input: React.createElement(Toggle, { value: !company.public_info.no_request_callback, setValue: () => handleResponsesToggle('no_request_callback') }) }))))),
        React.createElement(EstimateCoverNoteTemplateBlock, { company: company, setCompany: setCompany }));
};
