import React from 'react';
import { ClickableCard } from '../../components/content_display/card';
import { Badge } from '../../components/indicators_and_messaging/badge';
import { TableLite } from '../../components/content_display/table_lite';
import { DesignPageHeader } from './design/components/design_page_header';
import { PipeSectionCard } from './design/pages/pipework_design_page';
import { FlowTempSlider } from './design/pages/emitter_design_page';
export const DesignHome = ({ design, setDesign, currentHeatPump, currentHotWaterCylinder, setDesignTab, setPage, heatPumpCapacityResult, totalHeatLossKwSurvey, annualBillEstimateGBP, scop, minFlowTemp, maxFlowTemp, emitterColumns, emitterRows, reheatTimeHoursAndMinutes, hotWaterConsumptionAnnual, performanceEstimateColumns, hddPerformanceEstimate, soundAssessment, emitterDemandStatus, pipeData }) => {
    const setFlowTemp = (temp) => setDesign({ ...design, flow_temp: temp });
    // Demand calculations
    const heatPumpBadgeColor = heatPumpCapacityResult.capacityKw < totalHeatLossKwSurvey ? 'RED' : heatPumpCapacityResult.warning ? 'YELLOW' : 'GREEN';
    return React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
        React.createElement(DesignPageHeader, { title: 'Design', onBack: () => setPage(undefined) }),
        React.createElement("div", { className: 'grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-3 p-5 overflow-y-auto' },
            React.createElement(ClickableCard, { variant: 'GREY' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement(FlowTempSlider, { flowTemp: design.flow_temp, setFlowTemp: setFlowTemp, minFlowTemp: minFlowTemp, maxFlowTemp: maxFlowTemp }),
                    React.createElement("div", { className: "gap-3 flex justify-between" },
                        React.createElement("div", { className: "flex-col gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Heat loss"),
                            React.createElement("div", { className: "text-gray-600 text-base" },
                                totalHeatLossKwSurvey.toFixed(2),
                                " kW")),
                        React.createElement("div", { className: "flex-col gap-1 flex" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Running costs"),
                            React.createElement("div", { className: "text-gray-600 text-base" },
                                annualBillEstimateGBP,
                                " per year")),
                        React.createElement("div", { className: "flex-col gap-1.5 flex" },
                            React.createElement("div", { className: "text-gray-900 text-xs font-bold" }, "Emitters"),
                            totalHeatLossKwSurvey > 0 && React.createElement(Badge, { color: emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED', text: emitterDemandStatus }))))),
            React.createElement(ClickableCard, { variant: 'GREY', title: React.createElement("div", { className: 'flex gap-2 justify-between flex-grow' },
                    React.createElement("div", null, "Heat pump"),
                    totalHeatLossKwSurvey > 0 && React.createElement(Badge, { color: heatPumpBadgeColor, text: heatPumpBadgeColor === 'GREEN' ? 'Meets demand' : heatPumpBadgeColor === 'RED' ? 'Demand not met' : 'Data not available at design condition' })), onClick: () => setDesignTab('HEAT_PUMP') }, currentHeatPump ? React.createElement("div", { className: "flex-col gap-2 flex" },
                React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, currentHeatPump === null || currentHeatPump === void 0 ? void 0 : currentHeatPump.name),
                React.createElement("div", { className: "flex-col flex" },
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                            "SCOP at ",
                            design.flow_temp,
                            " \u00B0C"),
                        React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" }, scop)),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" },
                            "Capacity at ",
                            heatPumpCapacityResult.flowTempC,
                            " \u00B0C (",
                            heatPumpCapacityResult.outsideTempC,
                            " \u00B0C)"),
                        React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                            heatPumpCapacityResult.capacityKw,
                            " kW")),
                    React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                        React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Sound assessment"),
                        React.createElement(Badge, { color: soundAssessment === 0 ? 'YELLOW' : soundAssessment <= 42 ? 'GREEN' : 'RED', text: soundAssessment === 0 ? 'Incomplete' : soundAssessment <= 42 ? 'Pass' : 'Fail' })))) : React.createElement("div", null, "No heat pump")),
            React.createElement(ClickableCard, { variant: 'GREY', title: React.createElement("div", { className: 'flex gap-2 flex-grow justify-between' },
                    React.createElement("div", null, "Emitters"),
                    totalHeatLossKwSurvey > 0 && React.createElement(Badge, { color: emitterDemandStatus === 'Sufficient' ? 'GREEN' : emitterDemandStatus === 'Accepted' ? 'YELLOW' : 'RED', text: emitterDemandStatus })), onClick: () => setDesignTab('EMITTER') },
                React.createElement(TableLite, { columns: emitterColumns, rows: emitterRows })),
            React.createElement(ClickableCard, { variant: 'GREY', title: 'Cylinder', onClick: () => setDesignTab('CYLINDER') }, currentHotWaterCylinder
                ? React.createElement("div", { className: "flex-col gap-2 flex" },
                    React.createElement("div", { className: "text-gray-900 text-sm font-bold" }, currentHotWaterCylinder.name),
                    React.createElement("div", { className: "flex-col flex" },
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Capacity"),
                            React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                                Math.round(currentHotWaterCylinder.litres),
                                " litres")),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Reheat time"),
                            React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" }, reheatTimeHoursAndMinutes)),
                        React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                            React.createElement("div", { className: "text-gray-600 text-xs font-semibold" }, "Electricity for hot water"),
                            React.createElement("div", { className: "text-right text-gray-600 text-xs font-semibold" },
                                hotWaterConsumptionAnnual.toFixed(0),
                                " kWh"))))
                : React.createElement("div", null, "No cylinder")),
            React.createElement(ClickableCard, { variant: 'GREY', title: 'Pipework', onClick: () => setDesignTab('PIPEWORK') }, pipeData && React.createElement("div", { className: 'flex flex-col divide-y divide-gray-200' }, pipeData.map(x => React.createElement(PipeSectionCard, { key: x.id, pipeData: x })))),
            React.createElement(ClickableCard, { variant: 'GREY', title: 'Performance estimate', onClick: () => setDesignTab('PERFORMANCE') },
                React.createElement("div", { className: 'overflow-y-auto' },
                    React.createElement(TableLite, { columns: performanceEstimateColumns, rows: [hddPerformanceEstimate.existing, hddPerformanceEstimate.proposed, hddPerformanceEstimate.savings] })))));
};
