import React, { useState } from 'react';
import { updateCompanySettings } from '../../../../../code/models/company';
import { Section } from '../../../../../components/containers/section';
import { FormLabel } from '../../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../../components/inputs_and_selections/input';
import { Button } from '../../../../../components/buttons/button';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Heading } from '../../../../../components/content_display/heading';
import { Text } from '../../../../../components/content_display/text';
import { Link } from '../../../../../components/buttons/link';
import { noop } from 'lodash';
import { DEFAULT_BRAND_COLOUR_PRIMARY, DEFAULT_BRAND_COLOUR_SECONDARY, determineBestTextShade } from '../../../../../code/utils/colour_utils';
import { validateHexColourCode } from '../../../../../code/validators';
export const BrandColoursBlock = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const [editingCompany, setEditingCompany] = useState();
    const handleStartEditing = () => {
        setEditingCompany(company);
    };
    const handleUpdateCompany = async () => {
        if (editingCompany) {
            await updateCompanySettings(editingCompany);
            setCompany(editingCompany);
        }
    };
    const handleStopEditingCompany = () => {
        setEditingCompany(undefined);
    };
    const handleResetColours = async () => {
        const newCompany = {
            ...company,
            public_info: {
                ...company.public_info,
                colour_primary: DEFAULT_BRAND_COLOUR_PRIMARY,
                colour_secondary: DEFAULT_BRAND_COLOUR_SECONDARY
            }
        };
        await updateCompanySettings(newCompany);
        setCompany(newCompany);
    };
    const isValidForm = [validateHexColourCode((_a = editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.public_info.colour_primary) !== null && _a !== void 0 ? _a : DEFAULT_BRAND_COLOUR_PRIMARY), validateHexColourCode((_b = editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.public_info.colour_secondary) !== null && _b !== void 0 ? _b : DEFAULT_BRAND_COLOUR_SECONDARY)].every(x => x.value);
    return React.createElement(Section, { border: true, title: "Company colours" },
        React.createElement("div", { className: 'grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2' },
            React.createElement("div", { className: 'flex flex-col justify-between gap-4 row-start-2 md:row-start-1' },
                React.createElement("div", { className: 'flex flex-col gap-4' },
                    React.createElement(FormLabel, { labelText: 'Primary', helperText: 'Used for main buttons.' }),
                    editingCompany ? React.createElement(ColorInput, { value: (_c = editingCompany.public_info.colour_primary) !== null && _c !== void 0 ? _c : DEFAULT_BRAND_COLOUR_PRIMARY.toUpperCase(), setValue: colour => {
                            if (!colour)
                                return;
                            setEditingCompany(prev => ({ ...prev, public_info: { ...prev.public_info, colour_primary: colour } }));
                        } }) : React.createElement("div", { style: { backgroundColor: (_d = company.public_info.colour_primary) !== null && _d !== void 0 ? _d : DEFAULT_BRAND_COLOUR_PRIMARY }, className: 'sm:w-1/2 h-10 rounded-lg border' }),
                    React.createElement(FormLabel, { labelText: 'Secondary', helperText: 'Used for background accents.' }),
                    editingCompany ? React.createElement(ColorInput, { value: (_e = editingCompany.public_info.colour_secondary) !== null && _e !== void 0 ? _e : DEFAULT_BRAND_COLOUR_SECONDARY.toUpperCase(), setValue: colour => {
                            if (!colour)
                                return;
                            setEditingCompany(prev => ({ ...prev, public_info: { ...prev.public_info, colour_secondary: colour } }));
                        } }) : React.createElement("div", { style: { backgroundColor: (_f = company.public_info.colour_secondary) !== null && _f !== void 0 ? _f : DEFAULT_BRAND_COLOUR_SECONDARY }, className: 'sm:w-1/2 h-10 rounded-lg border' })),
                React.createElement("div", { className: 'flex gap-x-2 ' },
                    !editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Button, { colour: 'LIGHT', onClick: handleStartEditing }, "Edit"),
                        React.createElement(Button, { colour: 'LIGHT', iconLeft: faRefresh, disabled: (!company.public_info.colour_primary || company.public_info.colour_primary === DEFAULT_BRAND_COLOUR_PRIMARY) && (!company.public_info.colour_secondary || company.public_info.colour_secondary === DEFAULT_BRAND_COLOUR_SECONDARY), onClick: handleResetColours }, "Reset to defaults")),
                    editingCompany &&
                        React.createElement(React.Fragment, null,
                            React.createElement(Button, { onClick: () => {
                                    handleUpdateCompany();
                                    handleStopEditingCompany();
                                }, disabled: !isValidForm }, "Save"),
                            React.createElement(Button, { colour: 'LIGHT', onClick: handleStopEditingCompany }, "Cancel")))),
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement(FormLabel, { labelText: 'Preview', helperText: 'This is what your estimates will look like' }),
                React.createElement("div", { style: { backgroundColor: (_g = company.public_info.colour_secondary) !== null && _g !== void 0 ? _g : DEFAULT_BRAND_COLOUR_SECONDARY }, className: 'w-full h-full border rounded-lg' },
                    React.createElement("div", { className: "px-6 py-10 flex flex-col items-center w-full gap-5 rounded-lg" },
                        React.createElement("div", { className: "flex flex-col gap-3 text-center" },
                            React.createElement(Heading, { size: "2xl", className: determineBestTextShade((_h = company.public_info.colour_secondary) !== null && _h !== void 0 ? _h : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Book a full heat loss survey today"),
                            React.createElement(Text, { className: determineBestTextShade((_j = company.public_info.colour_secondary) !== null && _j !== void 0 ? _j : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? 'text-black' : 'text-white' }, "Or request a callback to discuss your needs in more detail.")),
                        React.createElement("div", { className: "w-full max-w-80 flex flex-col gap-3 items-center" },
                            React.createElement(Button, { style: {
                                    backgroundColor: company.public_info.colour_primary,
                                    borderColor: company.public_info.colour_primary,
                                    color: determineBestTextShade((_k = company.public_info.colour_primary) !== null && _k !== void 0 ? _k : DEFAULT_BRAND_COLOUR_PRIMARY) === 'LIGHT' ? '#000000' : '#ffffff'
                                }, className: 'w-full', onClick: noop }, "View cost estimate"),
                            React.createElement(Button, { colour: 'LIGHT', className: 'w-full' }, "Request a callback"),
                            React.createElement(Link, { className: determineBestTextShade((_l = company.public_info.colour_secondary) !== null && _l !== void 0 ? _l : DEFAULT_BRAND_COLOUR_SECONDARY) === 'LIGHT' ? '' : 'text-white', text: 'I\u2019m not interested right now', onClick: noop })))))));
};
const ColorInput = ({ value, setValue, disabled }) => {
    const handleColourChange = (e) => {
        setValue(e.target.value);
    };
    return React.createElement("div", { className: 'flex gap-2' },
        React.createElement(Input, { value: value.toUpperCase(), setValue: e => setValue(e), validator: validateHexColourCode, validateImmediately: true }),
        React.createElement("div", { style: { backgroundColor: value }, className: `rounded-lg border max-h-9 border-gray-300 ${disabled === true ? 'opacity-70' : 'opacity-100'}` },
            React.createElement("input", { type: 'color', className: 'opacity-0 h-full disabled:cursor-auto cursor-pointer', onChange: handleColourChange, value: value })));
};
