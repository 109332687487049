import React, { useState } from 'react';
import { HLRHeader } from './hlr_header';
import { HLRTitlePage } from './hlr_title_page';
import { HLRContentsPage } from './hlr_contents_page';
import { HLRSummaryPage } from './hlr_summary_page';
import { HLRIntroductionPage } from './hlr_introduction_page';
import { HLRCalculationConditionsPage } from './hlr_calculation_conditions_page';
import { HLRHeatlossByElementPage } from './hlr_heatloss_by_element_page';
import { HLRHeatlossByRoomPage } from './hlr_heatloss_by_room_page';
import { HLRFloorHeatlossPage } from './hlr_floor_heatloss_page';
import { HLRRoomHeatlossPage } from './hlr_room_heatloss_page';
import { DesignPageHeader } from '../design/components/design_page_header';
import { Button } from '../../../components/buttons/button';
import { HLRHeatPump } from './hlr_heat_pump';
import { HLREmittersIntro } from './hlr_emitters_intro';
import { HLREmittersCurrent } from './hlr_emitters_current';
import { HLREmittersProposed } from './hlr_emitters_proposed';
import { HLRHotWater } from './hlr_hot_water';
import { HLRHotWaterDemand } from './hlr_hot_water_demand';
import { HLRSoundAssessment } from './hlr_sound_assessment';
import { HLRPESummary } from './hlr_pe_summary';
import { HLRPEDetailedResultsIntroduction } from './hlr_pe_detailed_results_intro';
import { HLRPEResultsTable } from './hlr_pe_results_table';
import { HLRPEInputsAndAssumptions } from './hlr_pe_inputs_and_assumptions';
import { HLRPESystemEfficiency1 } from './hlr_pe_system_efficiency_1';
import { HLRPESystemEfficiency2 } from './hlr_pe_system_efficiency_2';
import { HLRPEFlowTempChart } from './hlr_pe_flow_temp_chart';
import { HLRPEMCSKeyFacts } from './hlr_pe_mcs_key_facts';
import { HeatLossReportSettings } from './heatloss_report_settings';
import { useLocalStorage } from '../../../code/use_local_storage';
import { getFullEmittersListByStatus } from '../../../code/models/radiator';
export const HLRContext = React.createContext(undefined);
const AVAILABLE_SECTIONS = [
    'SUMMARY',
    'HEAT_LOSS',
    'HEAT_LOSS_INTRODUCTION',
    'HEAT_LOSS_ROOM_BY_ROOM',
    'DESIGN',
    'SOUND_ASSESSMENT',
    'PERFORMANCE_ESTIMATE'
];
export const HeatLossReport = (props) => {
    const [page, setPage] = useState('SETTINGS');
    const [excludedSections, setExcludedSections] = useLocalStorage('excluded_report_sections', []);
    if (page === 'SETTINGS') {
        return React.createElement(HLRContext.Provider, { value: props },
            React.createElement(HeatLossReportSettings, { setReportPage: setPage, setPage: props.setPage, excludedSections: excludedSections, setExcludedSections: setExcludedSections }));
    }
    // If ONLY sound assessment don't show header and contents
    const dontShowContents = excludedSections.length === AVAILABLE_SECTIONS.length - 1 && !excludedSections.includes('SOUND_ASSESSMENT');
    const emittersDefined = getFullEmittersListByStatus(props.survey, props.design).length > 0;
    return (React.createElement(HLRContext.Provider, { value: props },
        React.createElement("div", { className: 'print:hidden' },
            React.createElement(DesignPageHeader, { title: 'Report preview', onBack: () => setPage('SETTINGS'), rightHandChildren: React.createElement(Button, { onClick: () => window.print() }, "Save as PDF") }),
            React.createElement("div", { className: "p-3 bg-yellow-100 rounded-lg flex" },
                React.createElement("div", { className: "flex-col gap-1 flex" },
                    React.createElement("div", { className: "text-yellow-900 text-base font-bold" }, "For best results, print from Chrome"),
                    React.createElement("div", { className: "text-yellow-900 text-sm" }, "Chrome currently provides the best output for customers.")))),
        React.createElement("div", { className: "h-28 w-[210mm] print:top-0 print:fixed px-9 print:px-0" },
            React.createElement(HLRHeader, null)),
        React.createElement("table", { className: 'print:mt-0' },
            React.createElement("thead", { className: 'print:table-header-group hidden' },
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement("div", { className: "print:h-28" }, "\u00A0")))),
            React.createElement("tbody", null,
                React.createElement("tr", null,
                    React.createElement("td", null,
                        React.createElement("div", { className: "mx-9 print:px-0 w-[210mm]" },
                            !dontShowContents && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'break-before-page' },
                                    React.createElement(HLRTitlePage, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRContentsPage, { excludedSections: excludedSections }))),
                            !excludedSections.includes('SUMMARY') && React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                React.createElement(HLRSummaryPage, null)),
                            !excludedSections.includes('HEAT_LOSS') && React.createElement(React.Fragment, null,
                                !excludedSections.includes('HEAT_LOSS_INTRODUCTION') && React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRIntroductionPage, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRCalculationConditionsPage, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRHeatlossByElementPage, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRHeatlossByRoomPage, null)),
                                props.survey.floors.map((floor, floorIdx) => {
                                    // skip floors without rooms
                                    if (floor.rooms.length === 0)
                                        return null;
                                    return (React.createElement("div", { key: 'floor-' + floorIdx },
                                        React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                            React.createElement(HLRFloorHeatlossPage, { floor: floor })),
                                        !excludedSections.includes('HEAT_LOSS_ROOM_BY_ROOM') && floor.rooms.sort((a, b) => a.name.localeCompare(b.name))
                                            .map((room, roomIdx) => {
                                            return React.createElement("div", { key: 'floor-' + floorIdx + '-room-' + roomIdx, className: 'break-before-page pt-10 print:pt-0' },
                                                React.createElement(HLRRoomHeatlossPage, { floor: floor, room: room }));
                                        })));
                                })),
                            !excludedSections.includes('DESIGN') && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRHeatPump, null)),
                                emittersDefined && React.createElement(React.Fragment, null,
                                    React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                        React.createElement(HLREmittersIntro, null)),
                                    React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                        React.createElement(HLREmittersCurrent, null)),
                                    React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                        React.createElement(HLREmittersProposed, null))),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRHotWater, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRHotWaterDemand, null))),
                            !excludedSections.includes('SOUND_ASSESSMENT') && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRSoundAssessment, null))),
                            !excludedSections.includes('PERFORMANCE_ESTIMATE') && React.createElement(React.Fragment, null,
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPESummary, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPEDetailedResultsIntroduction, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPEResultsTable, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPEInputsAndAssumptions, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPESystemEfficiency1, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPESystemEfficiency2, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPEFlowTempChart, null)),
                                React.createElement("div", { className: 'break-before-page pt-10 print:pt-0' },
                                    React.createElement(HLRPEMCSKeyFacts, null))))))))));
};
