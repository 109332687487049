import React, { useContext, useState } from 'react';
import { FloorAttributesBlock } from './floor_attributes_block';
import { isCompanyHasMagicPlanIntegration } from '../../../code/models/company';
import { Button } from '../../../components/buttons/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/free-solid-svg-icons';
import { MagicplanModal } from '../magicplan/magicplan_modal';
import { AdminContext } from '../../admin/admin_layout';
export const AddFloor = ({ survey, setPage, afterImport, floors, setCurrentFloorId, setSurvey }) => {
    // `setPages` also means that we are in the flow or not
    // if we are in the flow, `setPages` will be not set
    const adminContext = useContext(AdminContext);
    const [showMagicplanModal, setShowMagicplanModal] = useState(false);
    return React.createElement("div", { className: 'flex flex-col' },
        !setPage &&
            React.createElement("div", { className: "flex-col gap-3 flex" },
                React.createElement("div", { className: "text-gray-900 text-xl font-bold" }, "Add a floor"),
                React.createElement("div", { className: "" }, "Set the properties of the starting floor of the property.")),
        React.createElement("div", { className: `${setPage ? 'p-5' : ''} bg-white pt-5 flex-col gap-5 flex` },
            isCompanyHasMagicPlanIntegration(adminContext.data.company) && React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement(Button, { onClick: () => setShowMagicplanModal(prev => !prev), colour: 'DARK' },
                        React.createElement(FontAwesomeIcon, { icon: faCloudDownload }),
                        "Magicplan")),
                React.createElement("div", { className: 'text-gray-900 font-bold' }, "OR")),
            React.createElement(FloorAttributesBlock, { floors: floors, onSave: (newFloor) => {
                    setSurvey(prev => ({ ...prev, floors: [...prev.floors, newFloor] }));
                    setCurrentFloorId(newFloor.uuid);
                    if (setPage) {
                        setPage('FLOOR_DETAILS');
                    }
                } }),
            React.createElement(MagicplanModal, { survey: survey, setSurvey: setSurvey, showMagicplanModal: showMagicplanModal, setShowMagicplanModal: setShowMagicplanModal, afterImport: afterImport })));
};
