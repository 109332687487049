import React, { createContext, useEffect, useReducer, useState, useContext } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { getInitialSurveyState, stepHashPrefix, SurveyActionType, SurveyPagesEnum, surveyReducer } from '../../code/survey/survey';
import { DataLoaderContext } from './components/data_loader_context';
import { LogoHeader } from './components/logo_header';
import { ProgressBar } from './components/progress_bar';
import { BathroomsStep } from './steps/bathrooms_step';
import { BedroomsStep } from './steps/bedrooms_step';
import { ChooseAddressStep } from './steps/choose_address_step';
import { ConfirmLocationStep } from './steps/confirm_location_step';
import { CurrentHeatingTypeStep } from './steps/current_heating_type_step';
import { EnquiryReasonStep } from './steps/enquiry_reason_step';
import { EPCChangesStep } from './steps/epcchanges_step';
import { EPCFoundStep } from './steps/epcfound_step';
import { FloorAreaStep } from './steps/floor_area_step';
import { GetContactsStep } from './steps/get_contacts_step';
import { GlazingStep } from './steps/glazing_step';
import { PropertyTypeStep } from './steps/property_type_step';
import { LoftInsulationStep } from './steps/loft_insulation_step';
import { ManualAddressStep } from './steps/manual_address_step';
import { NoEPCFoundStep } from './steps/no_epcfound_step';
import { OutdoorSpaceStep } from './steps/outdoor_space_step';
import { ThankYouStep } from './steps/thank_you_step';
import { WallCavityInsulationStep } from './steps/wall_cavity_insulation_step';
import { WallSolidInsulationStep } from './steps/wall_solid_insulation_step';
import { WallTypeStep } from './steps/wall_type_step';
import { ConstructionAgeBandStep } from './steps/construction_age_band_step';
import { BuiltFormStep } from './steps/built_form_step';
import { ScottishGrantStep } from './steps/scottish_grant_step';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { AdminContext } from '../admin/admin_layout';
import { OfflinePage } from '../admin/offline_page';
import { USER_ROLE_HAS_SURVEY_ACCESS, USER_ROLE_SIMPLE } from '../../code/models/user';
import { RequireRole } from '../../require_role';
import { Loader } from '../../components/indicators_and_messaging/loader';
// used for reducer
export const SurveyContext = createContext(getInitialSurveyState(''));
// @ts-expect-error context should not start with null
export const SurveyDispatchContext = createContext(null);
// the whole idea of the wrapper is to reload the survey page when the user navigates to the same page
// because react-router renders component only once and doesn't re-render it when the URL changes back and forth
// inside the wrapper we have a key (a special ReactJS attribute) that changes every time the user navigates to the same page
// it solves the SPR-329: https://linear.app/spruce-eco/issue/SPR-329/enquiry-duplicates-information-from-the-previous-enquiry
export const SurveyPageWrapper = ({ showLogo, isAdmin }) => {
    const [reloadKey, setReloadKey] = useState('');
    const reload = () => {
        setReloadKey(crypto.randomUUID());
    };
    useEffect(() => {
        reload();
    }, []);
    if (isAdmin) {
        return (React.createElement(RequireRole, { roles: [USER_ROLE_SIMPLE, USER_ROLE_HAS_SURVEY_ACCESS] },
            React.createElement(HeatLossSurveyPage, { showLogo: showLogo, key: reloadKey, isAdmin: isAdmin })));
    }
    return React.createElement(HeatLossSurveyPage, { showLogo: showLogo, key: reloadKey, isAdmin: isAdmin });
};
export const HeatLossSurveyPage = ({ showLogo, isAdmin }) => {
    // source is used to track the source of the survey
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');
    const adminContext = useContext(AdminContext);
    const [loadingData, setLoadingData] = useState(false);
    const [page, setPage] = useState(null);
    const [hasProgressBar, setHasProgressBar] = useState(true);
    const companyPublicInfo = useRouteLoaderData('company_root');
    const [survey, dispatch] = useReducer(surveyReducer, source, getInitialSurveyState);
    if (!companyPublicInfo)
        return;
    // hasProgressBar: we have a progress bar on the top of the survey page,
    //      and we have to show it everywhere except some pages,
    //      so, this setting is used to hide the progress bar on the address lookup pages
    //      Also, moving it to the component state leads to infinitive re-rendering of the component
    //      this is why it's managed here as the function return value
    const selectPage = () => {
        switch (survey.currentPage) {
            // Address lookup pages
            case SurveyPagesEnum.ChooseAddressStep:
                return { page: React.createElement(ChooseAddressStep, null), hasProgressBar: true };
            case SurveyPagesEnum.ManualAddressStep:
                return { page: React.createElement(ManualAddressStep, null), hasProgressBar: true };
            case SurveyPagesEnum.ConfirmLocationStep:
                return { page: React.createElement(ConfirmLocationStep, null), hasProgressBar: true };
            case SurveyPagesEnum.NoEPCFoundStep:
                return { page: React.createElement(NoEPCFoundStep, null), hasProgressBar: true };
            case SurveyPagesEnum.EPCFoundStep:
                return { page: React.createElement(EPCFoundStep, null), hasProgressBar: true };
            // Survey pages
            case SurveyPagesEnum.PropertyTypeStep:
                return { page: React.createElement(PropertyTypeStep, null), hasProgressBar: true };
            case SurveyPagesEnum.BuiltFormStep:
                return { page: React.createElement(BuiltFormStep, null), hasProgressBar: true };
            case SurveyPagesEnum.OutdoorSpaceStep:
                return { page: React.createElement(OutdoorSpaceStep, null), hasProgressBar: true };
            case SurveyPagesEnum.BedroomsStep:
                return { page: React.createElement(BedroomsStep, null), hasProgressBar: true };
            case SurveyPagesEnum.BathroomsStep:
                return { page: React.createElement(BathroomsStep, null), hasProgressBar: true };
            case SurveyPagesEnum.CurrentHeatingTypeStep:
                return { page: React.createElement(CurrentHeatingTypeStep, null), hasProgressBar: true };
            case SurveyPagesEnum.EPCChangesStep:
                return { page: React.createElement(EPCChangesStep, null), hasProgressBar: true };
            case SurveyPagesEnum.ConstructionAgeBandStep:
                return { page: React.createElement(ConstructionAgeBandStep, null), hasProgressBar: true };
            case SurveyPagesEnum.FloorAreaStep:
                return { page: React.createElement(FloorAreaStep, null), hasProgressBar: true };
            case SurveyPagesEnum.WallTypeStep:
                return { page: React.createElement(WallTypeStep, null), hasProgressBar: true };
            case SurveyPagesEnum.WallCavityInsulationStep:
                return { page: React.createElement(WallCavityInsulationStep, null), hasProgressBar: true };
            case SurveyPagesEnum.WallSolidInsulationStep:
                return { page: React.createElement(WallSolidInsulationStep, null), hasProgressBar: true };
            case SurveyPagesEnum.GlazingStep:
                return { page: React.createElement(GlazingStep, null), hasProgressBar: true };
            case SurveyPagesEnum.LoftInsulationStep:
                return { page: React.createElement(LoftInsulationStep, null), hasProgressBar: true };
            // Final pages with results and contacts
            case SurveyPagesEnum.EnquiryReasonStep:
                return { page: React.createElement(EnquiryReasonStep, null), hasProgressBar: true };
            case SurveyPagesEnum.ScottishGrantStep:
                return { page: React.createElement(ScottishGrantStep, null), hasProgressBar: true };
            case SurveyPagesEnum.GetContactsStep:
                return { page: React.createElement(GetContactsStep, { adminEstimate: Boolean(adminContext.data.company) }), hasProgressBar: true };
            case SurveyPagesEnum.ThankYouStep:
                return { page: React.createElement(ThankYouStep, null), hasProgressBar: true };
            default:
                throw new Error('Unknown page');
        }
    };
    const handleHashChange = () => {
        // Handle hash changes
        const stepString = window.location.hash.substring(stepHashPrefix.length);
        // if last page in the stack is the page in the Hash, then we are going back
        if (survey.pagesStack.length !== 0 && survey.pagesStack[survey.pagesStack.length - 1].page === Number(stepString)) {
            dispatch({
                type: SurveyActionType.NavigatePreviousPage
            });
        }
        else {
            // ignore changes in the URL hash
            // this is going to happen probably if user manually changes the URL
            // FIXME: the code below is for Forward navigation, but it doesn't logically work
            // dispatch({
            //   type: SurveyActionType.NavigateToPageFromParams,
            //   payload: {
            //     page: Number(stepString) as SurveyPagesEnum
            //   }
            // })
        }
    };
    // Listen for hash changes
    React.useEffect(() => {
        // this is going to be called ONLY when user changes URL manually or by clicking on the back button
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            // Clean up the event listener when the component unmounts
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
    // re-render only when the currentPage changes
    useEffect(() => {
        // ensure the URL hash contains the current page
        // save current page to the browser history
        window.location.hash = `${stepHashPrefix}${survey.currentPage}`;
        // select the page to render
        const { page, hasProgressBar } = selectPage();
        setPage(page);
        setHasProgressBar(hasProgressBar);
    }, [survey.currentPage]);
    if (isAdmin && adminContext.isOffline) {
        return React.createElement(OfflinePage, { backURL: `/${companyPublicInfo.subdomain}/admin/surveys/all` });
    }
    return (
    // pass the survey state and dispatch to all children
    React.createElement(SurveyContext.Provider, { value: survey },
        React.createElement(SurveyDispatchContext.Provider, { value: dispatch },
            React.createElement("div", { className: "flex flex-col" },
                React.createElement("div", { className: !hasProgressBar ? 'border-solid border-b border-gray-300 ' : '' },
                    React.createElement("div", { className: 'flex items-center px-4' },
                        isAdmin && React.createElement(FontAwesomeIcon, { className: 'cursor-pointer text-gray-600 lg:hidden', icon: faBars, onClick: () => adminContext.isSidebarOpen ? adminContext.hideSidebar() : adminContext.showSidebar() }),
                        showLogo && React.createElement(LogoHeader, { companyPublicInfo: companyPublicInfo })),
                    hasProgressBar && React.createElement(ProgressBar, { progress: survey.pbValue })),
                React.createElement(DataLoaderContext.Provider, { value: { loadingData, setLoadingData } },
                    React.createElement("div", { className: "px-7 py-8 flex-col gap-8 flex max-w-xl mx-auto w-full" },
                        loadingData && React.createElement(Loader, null),
                        !loadingData && page))))));
};
