import React, { useEffect, useState } from 'react';
import { faShareFromSquare } from '@fortawesome/free-regular-svg-icons';
import { Icon } from '../../../../components/buttons/icon';
import { Section } from '../../../../components/containers/section';
import { Button } from '../../../../components/buttons/button';
import { Input } from '../../../../components/inputs_and_selections/input';
import { Select } from '../../../../components/inputs_and_selections/select';
import { getSimproAPICompaniesList, updateCompanySettings } from '../../../../code/models/company';
import { SettingsLayout } from '../components/settings_layout';
import { Info } from '../../../../components/buttons/info';
import { testMagicplanCreds } from '../../../../code/floorplan_adapters/magicplan';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
export const AccountIntegrations = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [editingCompany, setEditingCompany] = useState();
    const [simproCompanies, setSimproCompanies] = useState([]);
    const [testingMagicplan, setTestingMagicplan] = useState(undefined);
    const getSimproCompaniesList = async (apiKey, url) => {
        if (!apiKey || !url)
            return [];
        const list = await getSimproAPICompaniesList(company.public_info.uuid, apiKey, url);
        setSimproCompanies(list || []);
    };
    useEffect(() => {
        if ((editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_api_key) && (editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_customer_id)) {
            setTestingMagicplan('TESTING');
            setTimeout(async () => {
                if ((editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_api_key) && (editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_customer_id)) {
                    setTestingMagicplan('TESTING');
                    const projects = await testMagicplanCreds(editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.public_info.uuid, editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_api_key, editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_customer_id);
                    if (!projects || projects === -1) {
                        setTestingMagicplan('UNSUCCESSFUL');
                    }
                    else {
                        setTestingMagicplan('SUCCESSFUL');
                    }
                }
                else {
                    setTestingMagicplan(undefined);
                }
            }, 1000);
        }
        return () => {
            setTestingMagicplan(undefined);
        };
    }, [editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_api_key, editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.magicplan_customer_id]);
    return React.createElement(SettingsLayout, { heading: 'Integrations', description: 'Connect Spruce to your other apps' },
        React.createElement(Section, { border: true, title: 'Your integrations' },
            React.createElement("div", { className: 'flex flex-col gap-4' },
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row items-center gap-2' },
                            React.createElement("div", { className: 'font-bold' }, "Payaca Customer ID"),
                            React.createElement(Info, { infoModalHeader: 'Payaca integration', infoModalBody: React.createElement("span", null, "If you have a Payaca CRM add your customer ID here and we will automatically add new leads to your Payaca system."), colour: 'text-gray-400' }))),
                    editingCompany &&
                        React.createElement(Input, { value: (_a = editingCompany.payaca_customer_id) !== null && _a !== void 0 ? _a : '', setValue: (e) => setEditingCompany(prev => ({ ...prev, payaca_customer_id: e })) }),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, !company.payaca_customer_id ? '(not set)' : company.payaca_customer_id)),
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row items-center gap-2' },
                            React.createElement("div", { className: 'font-bold' }, "Commusoft Integration"),
                            React.createElement(Info, { infoModalHeader: 'Commusoft integration', infoModalBody: React.createElement("span", null, "If you have a Commusoft account add your Client ID here and we will automatically add new leads to your Commusoft system."), colour: 'text-gray-400' }))),
                    editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'Client ID', value: (_b = editingCompany.commusoft_client_id) !== null && _b !== void 0 ? _b : '', setValue: (e) => setEditingCompany(prev => ({ ...prev, commusoft_client_id: e })) }),
                        React.createElement(Input, { placeholder: 'API Key', value: (_c = editingCompany.commusoft_api_key) !== null && _c !== void 0 ? _c : '', setValue: (e) => setEditingCompany(prev => ({ ...prev, commusoft_api_key: e })) })),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, !company.commusoft_client_id || !company.commusoft_api_key ? '(not set)' : 'Activated for ClientID: ' + company.commusoft_client_id)),
                React.createElement("div", { className: 'space-y-2' },
                    React.createElement("div", { className: 'flex flex-col' },
                        React.createElement("div", { className: 'flex flex-row items-center gap-2' },
                            React.createElement("div", { className: 'font-bold' }, "Simpro Integration"),
                            React.createElement(Info, { infoModalHeader: 'Simpro integration', infoModalBody: React.createElement("span", null, "If you have a Simpro account add your Spruce integration API key here and select the company in your Simpro Account. We will automatically add new leads to your Simpro system."), colour: 'text-gray-400' }))),
                    editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'API Key', value: (_d = editingCompany.simpro_api_key) !== null && _d !== void 0 ? _d : '', setValue: async (e) => {
                                var _a;
                                setEditingCompany(prev => ({
                                    ...prev,
                                    simpro_api_key: e,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                }));
                                await getSimproCompaniesList(e.trim(), ((_a = editingCompany.simpro_url) !== null && _a !== void 0 ? _a : '').trim());
                            } }),
                        React.createElement(Input, { placeholder: 'URL', value: (_e = editingCompany.simpro_url) !== null && _e !== void 0 ? _e : '', setValue: async (e) => {
                                var _a;
                                setEditingCompany(prev => ({
                                    ...prev,
                                    simpro_url: e,
                                    simpro_company_id: '',
                                    simpro_company_name: ''
                                }));
                                await getSimproCompaniesList(((_a = editingCompany.simpro_api_key) !== null && _a !== void 0 ? _a : '').trim(), e.trim());
                            } }),
                        simproCompanies.length === 0 && React.createElement(Alert, { type: 'WARNING' }, "No companies found for the API key"),
                        simproCompanies.length !== 0 && React.createElement(React.Fragment, null,
                            !editingCompany.simpro_company_id && React.createElement(Alert, { type: 'INFO' }, "Please select a Simpro company"),
                            React.createElement(Select, { options: simproCompanies, selectedKey: editingCompany.simpro_company_id, setSelectedKey: (e) => {
                                    setEditingCompany(prev => {
                                        var _a, _b;
                                        return ({
                                            ...prev,
                                            simpro_company_id: e,
                                            simpro_company_name: (_b = (_a = simproCompanies.find(x => x.key === e)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''
                                        });
                                    });
                                } }))),
                    !editingCompany &&
                        React.createElement("div", { className: 'flex flex-col' },
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_api_key ? 'API key: (not set)' : company.simpro_api_key),
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_url ? 'URL: (not set)' : company.simpro_url),
                            React.createElement("div", { className: 'text-gray-600' }, !company.simpro_company_name ? 'Company ID: (not set)' : company.simpro_company_name + ' (ID=' + company.simpro_company_id + ')')),
                    React.createElement("div", { className: 'flex pt-4 flex-row gap-2 items-center' },
                        React.createElement("div", { className: 'font-bold' }, "Magicplan integration"),
                        React.createElement(Info, { infoModalHeader: 'Magicplan integration', infoModalBody: React.createElement("span", null,
                                "Get your Magicplan API key and CustomerID from",
                                React.createElement("br", null),
                                React.createElement("a", { target: '_blank', className: 'underline text-sky-800', href: 'https://cloud.magicplan.app/integrations', rel: "noreferrer" }, "your Magicplan account"),
                                " ",
                                React.createElement(Icon, { icon: faShareFromSquare }),
                                " and add them here."), colour: 'text-gray-400' })),
                    editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Input, { placeholder: 'API Key', value: (_f = editingCompany.magicplan_api_key) !== null && _f !== void 0 ? _f : '', setValue: (e) => {
                                setEditingCompany(prev => ({ ...prev, magicplan_api_key: e }));
                            } }),
                        React.createElement(Input, { placeholder: 'Customer ID', value: (_g = editingCompany.magicplan_customer_id) !== null && _g !== void 0 ? _g : '', setValue: (e) => {
                                setEditingCompany(prev => ({ ...prev, magicplan_customer_id: e }));
                            } }),
                        testingMagicplan === 'TESTING' &&
                            React.createElement(Alert, { type: 'INFO' }, "Verifying Magicplan integration details..."),
                        testingMagicplan === 'SUCCESSFUL' &&
                            React.createElement(Alert, { type: 'SUCCESS' }, "You've successfully integrated Magicplan with Spruce!"),
                        testingMagicplan === 'UNSUCCESSFUL' &&
                            React.createElement(Alert, { type: 'WARNING' }, "Error getting Magicplan projects. Check your Magicplan API key and Customer ID.")),
                    !editingCompany &&
                        React.createElement("div", { className: 'text-gray-600' }, company.magicplan_customer_id ? `Enabled for CustomerID ${company.magicplan_customer_id}` : '(not set)'))),
            React.createElement("div", { className: 'flex gap-x-2 pt-8' },
                !editingCompany && React.createElement(Button, { colour: 'LIGHT', size: 'MD', onClick: async () => {
                        setEditingCompany(company);
                    } }, "Edit"),
                editingCompany && React.createElement(React.Fragment, null,
                    React.createElement(Button, { onClick: async () => {
                            await updateCompanySettings(editingCompany);
                            setCompany(editingCompany);
                            setEditingCompany(undefined);
                        } }, "Save"),
                    React.createElement(Button, { colour: 'LIGHT', onClick: () => setEditingCompany(undefined) }, "Cancel")))));
};
