import React, { useState } from 'react';
import { DesignPageHeader } from '../components/design_page_header';
import { Input } from '../../../../components/inputs_and_selections/input';
import { getDailyHotWaterVolumeL, getNumberOfOccupants, getNumberOfOccupantsNoOverride } from '../../../../code/models/hot_water_cylinder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { SurveyCylinderLocationContext } from '../../survey/cylinder_location';
import { CylinderLocationProposedPhotosBlock } from '../../survey/cylinder_location/cylinder_location_proposed_photos_block';
import { CylinderLocationProposedLocationBlock } from '../../survey/cylinder_location/cylinder_location_proposed_location_block';
import { Icon } from '../../../../components/buttons/icon';
import { ClickableCard } from '../../../../components/content_display/card';
import { ListItem } from '../../../../components/content_display/list_item';
import { Badge } from '../../../../components/indicators_and_messaging/badge';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
export const CylinderDesignPage = ({ setDesignTab, currentHotWaterCylinderId, hotWaterCylinders, survey, setSurvey, cylinderReheatRow, hotWaterRowNormal, hotWaterRowLegionella, design, setDesign, minFlowTemp, maxFlowTemp, files, setFiles, companyUUID }) => {
    const [page, setPage] = useState('MAIN');
    if (page === 'DEMAND')
        return React.createElement(DemandPage, { survey: survey, setSurvey: setSurvey, setPage: setPage });
    const currentHotWaterCylinder = hotWaterCylinders.find(x => x.uuid === currentHotWaterCylinderId);
    const numberOfOccupants = getNumberOfOccupants(survey);
    const dailyHotWaterVolumeL = getDailyHotWaterVolumeL(numberOfOccupants, survey.volume_per_person_l);
    const hotWaterConsumptionAnnual = hotWaterRowNormal.electricityKWhPerYear + hotWaterRowLegionella.electricityKWhPerYear;
    if (page === 'SELECT_CYLINDER') {
        return React.createElement("div", { className: 'flex flex-col' },
            React.createElement(DesignPageHeader, { title: 'Select a cylinder', onBack: () => setPage('MAIN') }),
            React.createElement("div", { className: "bg-white rounded-md flex-col flex divide-y divide-gray-200" }, hotWaterCylinders.map(x => React.createElement(ListItem, { onClick: () => {
                    setDesign({ ...design, current_hot_water_cylinder_uuid: x.uuid });
                    setPage('MAIN');
                }, key: x.uuid, primaryText: x.name, rightBadge: React.createElement(Badge, { color: 'LIGHT', text: Math.round(x.litres) + ' litres' }) }))));
    }
    return React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'flex flex-col h-full min-h-0' },
            React.createElement(DesignPageHeader, { title: 'Hot water & cylinder', onBack: () => setDesignTab(undefined) }),
            React.createElement("div", { className: 'flex flex-col gap-6 p-4 overflow-y-auto' },
                React.createElement("div", { className: 'flex flex-col gap-6 border-b border-gray-200 pb-6' },
                    React.createElement("div", { className: 'font-bold text-xl text-gray-900' }, "Demand"),
                    React.createElement("div", { className: 'flex justify-between cursor-pointer items-center', onClick: () => setPage('DEMAND') },
                        React.createElement("div", { className: 'flex flex-col gap-1 text-gray-600' },
                            React.createElement("div", { className: 'font-bold text-gray-900' },
                                dailyHotWaterVolumeL,
                                " litres per day"),
                            React.createElement("div", { className: 'text-sm' },
                                numberOfOccupants,
                                " occupants x ",
                                survey.volume_per_person_l,
                                " litres per occupant")),
                        React.createElement(FontAwesomeIcon, { className: 'text-gray-600', icon: faChevronRight }))),
                React.createElement("div", { className: 'flex flex-col gap-6' },
                    React.createElement("div", { className: 'font-bold text-gray-900 text-xl' }, "Supply"),
                    React.createElement("div", { className: 'flex flex-col gap-5' },
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'flex flex-col' },
                                React.createElement("div", { className: 'text-gray-900 font-bold' }, "Cylinder model"),
                                React.createElement("div", { className: 'text-gray-500 text-sm' }, "MCS guidance acknowledges that cylinder size may be limited by available space")),
                            currentHotWaterCylinder ? React.createElement(ClickableCard, { title: currentHotWaterCylinder.name, variant: 'WHITE', onClick: () => setPage('SELECT_CYLINDER') },
                                React.createElement("div", { className: "gap-2 flex justify-between" },
                                    React.createElement("div", { className: "text-xs font-semibold" }, "Capacity"),
                                    React.createElement("div", { className: "text-xs font-semibold" },
                                        Math.round(currentHotWaterCylinder.litres),
                                        " litres")))
                                : React.createElement(ClickableCard, { variant: 'PLACEHOLDER', onClick: () => setPage('SELECT_CYLINDER') }, "Please select a cylinder")),
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'font-bold text-gray-900' }, "Hot water storage temperature"),
                            React.createElement(Input, { validator: validateHaveHeatPumpSCOPAtHotWaterStorageTemp(minFlowTemp, maxFlowTemp), postfix: '\u00B0C', type: 'number', value: design.hot_water_storage_temperature_c.toString(), setValue: (e) => setDesign({ ...design, hot_water_storage_temperature_c: parseFloat(e) }) }),
                            cylinderReheatRow.capacityWarning &&
                                React.createElement(ClickableCard, { variant: 'WARNING', border: false, size: 'SM', className: 'text-xs px-5 py-2' },
                                    React.createElement("div", { className: "flex-col gap-2" },
                                        React.createElement("div", { className: "font-bold" }, " Warning"),
                                        React.createElement("div", null, cylinderReheatRow.capacityWarning)))),
                        React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Legionella cycle interval', helperText: 'The number of weeks between Legionella cycles. E.g. 2 means one cycle every two weeks' }), input: React.createElement(Input, { validator: validateLegionairreCycleInterval(), postfix: 'weeks', type: 'number', value: survey.legionnaires_cycle_weeks.toString(), setValue: async (e) => await setSurvey({ ...survey, legionnaires_cycle_weeks: parseFloat(e) }) }) })),
                    React.createElement("div", { className: 'border border-gray-200' }),
                    React.createElement("div", { className: 'flex flex-col gap-6' },
                        React.createElement("div", { className: 'font-bold text-xl text-gray-900' }, "Calculations"),
                        React.createElement("div", { className: 'flex flex-col gap-2' },
                            React.createElement("div", { className: 'flex justify-between font-bold text-gray-900' },
                                React.createElement("div", null, "Calculated reheat time"),
                                React.createElement("div", null, cylinderReheatRow.reheatTimeHoursAndMinutes)),
                            React.createElement("div", { className: "flex-col flex text-xs" },
                                React.createElement("div", { className: "py-2 justify-between gap-2 flex" },
                                    React.createElement("div", null, "Cylinder volume"),
                                    React.createElement("div", null,
                                        Math.round(cylinderReheatRow.cylinderVolumeL),
                                        " litres")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                    React.createElement("div", null, "Assumed mains water temp"),
                                    React.createElement("div", null,
                                        cylinderReheatRow.coldWaterTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                    React.createElement("div", null, "Heat pump flow temp"),
                                    React.createElement("div", null,
                                        cylinderReheatRow.flowTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex" },
                                    React.createElement("div", null,
                                        "Heat pump capacity (",
                                        cylinderReheatRow.flowTempC,
                                        " \u00B0C flow, ",
                                        cylinderReheatRow.outsideTempC,
                                        " \u00B0C air)"),
                                    React.createElement("div", null,
                                        cylinderReheatRow.heatingPowerKw,
                                        " kW")))),
                        React.createElement("div", { className: 'flex flex-col gap-4' },
                            React.createElement("div", { className: 'flex justify-between font-bold text-gray-900' },
                                React.createElement("div", null, "Calculated hot water demand"),
                                React.createElement("div", null,
                                    hotWaterConsumptionAnnual.toFixed(0),
                                    " kWh")),
                            React.createElement("div", { className: "flex-col flex text-xs" },
                                React.createElement("div", { className: "py-2 justify-between gap-2 flex text-sm font-bold" },
                                    React.createElement("div", null, "Normal operation"),
                                    React.createElement("div", null,
                                        (hotWaterRowNormal.electricityKWhPerYear).toFixed(0),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-2" },
                                    React.createElement("div", null, "Electricity per day"),
                                    React.createElement("div", null,
                                        hotWaterRowNormal.electricityKwhPerCycle.toFixed(2),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-4" },
                                    React.createElement("div", null, "Heat energy per day"),
                                    React.createElement("div", null,
                                        hotWaterRowNormal.heatEnergyKwhPerCycle.toFixed(2),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Volume per day"),
                                    React.createElement("div", null,
                                        dailyHotWaterVolumeL,
                                        " litres")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Assumed mains water temp"),
                                    React.createElement("div", null,
                                        hotWaterRowNormal.coldWaterTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Storage temp"),
                                    React.createElement("div", null,
                                        hotWaterRowNormal.hotWaterTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Distribution efficiency"),
                                    React.createElement("div", null,
                                        hotWaterRowNormal.distributionEfficiency * 100,
                                        " %")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-4" },
                                    React.createElement("div", null,
                                        "Heat pump sCOP (",
                                        hotWaterRowNormal.flowTempC,
                                        " \u00B0C flow)"),
                                    hotWaterRowNormal.heatingEfficiency > 0 && React.createElement("div", null,
                                        (hotWaterRowNormal.heatingEfficiency * 100).toFixed(0),
                                        " %"),
                                    hotWaterRowNormal.heatingEfficiency === 0 && React.createElement(Badge, { color: 'RED', text: `${hotWaterRowNormal.heatingEfficiency} %` })),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-2" },
                                    React.createElement("div", null, "Days per year"),
                                    React.createElement("div", null, hotWaterRowNormal.cyclesPerYear))),
                            React.createElement("div", { className: "flex-col flex text-xs" },
                                React.createElement("div", { className: "py-2 justify-between gap-2 flex text-sm font-bold" },
                                    React.createElement("div", null, "Legionella cycles"),
                                    React.createElement("div", null,
                                        (hotWaterRowLegionella.electricityKWhPerYear).toFixed(0),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-2" },
                                    React.createElement("div", null, "Electricity per cycle"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.electricityKwhPerCycle.toFixed(2),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-4" },
                                    React.createElement("div", null, "Heat energy per cycle"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.heatEnergyKwhPerCycle.toFixed(2),
                                        " kWh")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Volume per cycle"),
                                    React.createElement("div", null,
                                        Math.round(cylinderReheatRow.cylinderVolumeL),
                                        " litres")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Tank starting temperature"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.coldWaterTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Legionella set point"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.hotWaterTempC,
                                        " \u00B0C")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-6" },
                                    React.createElement("div", null, "Distribution efficiency"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.distributionEfficiency * 100,
                                        " %")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-4" },
                                    React.createElement("div", null, "Legionella cycle efficiency"),
                                    React.createElement("div", null,
                                        hotWaterRowLegionella.heatingEfficiency * 100,
                                        " %")),
                                React.createElement("div", { className: "py-2 border-t border-gray-200 justify-between gap-2 flex indent-2" },
                                    React.createElement("div", null, "Cycles per year"),
                                    React.createElement("div", null, (hotWaterRowLegionella.cyclesPerYear).toFixed(0)))))),
                    React.createElement("div", { className: 'border border-gray-200' }),
                    React.createElement("div", { className: 'flex flex-col gap-6' },
                        React.createElement("div", { className: 'font-bold text-xl text-gray-900' }, "Location"),
                        React.createElement(SurveyCylinderLocationContext.Provider, { value: { survey, setSurvey, files, setFiles, companyUUID } },
                            React.createElement(CylinderLocationProposedPhotosBlock, null),
                            React.createElement(CylinderLocationProposedLocationBlock, null)))))));
};
const DemandPage = ({ survey, setSurvey, setPage }) => {
    var _a, _b;
    return React.createElement("div", { className: 'flex flex-col h-full' },
        React.createElement(DesignPageHeader, { title: 'Domestic hot water', onBack: () => setPage('MAIN') }),
        React.createElement("div", { className: 'flex flex-col gap-5 p-4 overflow-y-auto' },
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Bedrooms"),
                React.createElement(Input, { type: 'number', value: survey.bedrooms.toString(), setValue: (e) => setSurvey({ ...survey, bedrooms: parseFloat(e) }) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Occupants"),
                React.createElement(Input, { type: 'number', value: (_b = ((_a = survey.occupants_override) !== null && _a !== void 0 ? _a : survey.bedrooms + 1).toString()) !== null && _b !== void 0 ? _b : '', setValue: (e) => setSurvey({ ...survey, occupants_override: Number(e) }), postfix: React.createElement("div", { className: "gap-2.5 flex items-center" }, (survey.occupants_override !== undefined && (survey.occupants_override !== getNumberOfOccupantsNoOverride(survey))) &&
                        React.createElement(Icon, { icon: faTimesCircle, onClick: () => setSurvey(({ ...survey, occupants_override: undefined })), colour: 'text-gray-400' })) })),
            React.createElement("div", { className: 'flex flex-col gap-2' },
                React.createElement("div", { className: 'font-bold text-gray-900' }, "Daily volume per person"),
                React.createElement(Input, { type: 'number', value: survey.volume_per_person_l.toString(), setValue: (e) => setSurvey({ ...survey, volume_per_person_l: parseFloat(e) }), postfix: 'litres' }))));
};
export const validateHaveHeatPumpSCOPAtHotWaterStorageTemp = (minFlowTemp, maxFlowTemp) => (value) => {
    if (minFlowTemp === 0 || maxFlowTemp === 0)
        return { value: undefined, message: 'Please select a heat pump before setting the hot water design parameters' };
    const parsedValue = (typeof value === 'string') ? parseFloat(value) : value;
    if (parsedValue === undefined || isNaN(parsedValue))
        return { value: undefined, message: 'Please enter a value' };
    const flowTempC = parsedValue + 5;
    const isValid = flowTempC >= minFlowTemp && flowTempC <= maxFlowTemp;
    return isValid ? { value: parsedValue, message: '' } : { value: undefined, message: `We don't have sCOP data for this heat pump at the flow temp required for a hot water storage temperature of ${parsedValue}. Please enter a number between ${minFlowTemp - 5} and ${maxFlowTemp - 5}.` };
};
export const validateLegionairreCycleInterval = () => (value) => {
    const parsedValue = (typeof value === 'string') ? parseFloat(value) : value;
    if (parsedValue === undefined || isNaN(parsedValue))
        return { value: undefined, message: 'Please enter a value' };
    const isValid = parsedValue > 0;
    return isValid ? { value: parsedValue, message: '' } : { value: undefined, message: 'Please enter a value of 1 or greater. This is the number of weeks between Legionella cycles. E.g. 2 would be one Legionella cycle every two weeks' };
};
