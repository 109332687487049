import React, { useState } from 'react';
import { SettingsLayout } from '../components/settings_layout';
import { updateCompanySettings } from '../../../../code/models/company';
import { Section } from '../../../../components/containers/section';
import { Alert } from '../../../../components/indicators_and_messaging/alert';
import { VerticalFormGroup } from '../../../../components/inputs_and_selections/vertical_form_group';
import { FormLabel } from '../../../../components/inputs_and_selections/form_label';
import { Input } from '../../../../components/inputs_and_selections/input';
import { FlowTempSlider } from '../../../heat_loss/design/pages/emitter_design_page';
import { Button } from '../../../../components/buttons/button';
import { DEFAULT_AIR_CHANGES, DEFAULT_FLOW_TEMP_C, DEFAULT_INTERNAL_TEMP_C } from '../../../../code/calculate_estimate';
import { validateIsPositiveNumberOrZero } from '../../../../code/validators';
export const EstimatePreferences = ({ company, setCompany }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const [editingCompany, setEditingCompany] = useState();
    const handleStartEditing = () => {
        var _a, _b, _c;
        // If the company doesn't have their default values set yet, set them to the defaults.
        setEditingCompany({
            ...company,
            estimate_default_internal_temp_c: (_a = company.estimate_default_internal_temp_c) !== null && _a !== void 0 ? _a : DEFAULT_INTERNAL_TEMP_C,
            estimate_default_ach: (_b = company.estimate_default_ach) !== null && _b !== void 0 ? _b : DEFAULT_AIR_CHANGES,
            default_flow_temp_c: (_c = company.default_flow_temp_c) !== null && _c !== void 0 ? _c : DEFAULT_FLOW_TEMP_C
        });
    };
    const handleUpdateCompany = async () => {
        if (editingCompany) {
            await updateCompanySettings(editingCompany);
            setCompany(editingCompany);
        }
    };
    const handleStopEditingCompany = () => {
        setEditingCompany(undefined);
    };
    const isValidForm = [validateIsPositiveNumberOrZero((_a = editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.estimate_default_internal_temp_c) !== null && _a !== void 0 ? _a : ''), validateIsPositiveNumberOrZero((_b = editingCompany === null || editingCompany === void 0 ? void 0 : editingCompany.estimate_default_ach) !== null && _b !== void 0 ? _b : '')].every(x => x.value);
    return React.createElement(SettingsLayout, { heading: 'Estimate preferences', description: 'Configure how your estimates are generated' },
        React.createElement(Section, { border: true, title: 'Heat loss estimate defaults' },
            React.createElement("div", { className: 'flex flex-col gap-8' },
                React.createElement(Alert, { type: 'INFO' }, "Changing these settings will not impact estimates that have already been sent to customers."),
                React.createElement("div", { className: 'grid grid-cols-1 sm:grid-cols-2 grid-flow-row gap-4' },
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'Internal temp. (\u00B0C)' }), input: editingCompany ? React.createElement(Input, { type: 'number', disabled: !editingCompany, value: (_c = (editingCompany !== null && editingCompany !== void 0 ? editingCompany : company).estimate_default_internal_temp_c) !== null && _c !== void 0 ? _c : '', setValue: temp => setEditingCompany(editingCompany && { ...editingCompany, estimate_default_internal_temp_c: parseInt(temp) }), validator: validateIsPositiveNumberOrZero, validateImmediately: true, postfix: React.createElement(React.Fragment, null, "\u00B0C") }) : React.createElement("div", null, (_d = company === null || company === void 0 ? void 0 : company.estimate_default_internal_temp_c) !== null && _d !== void 0 ? _d : DEFAULT_INTERNAL_TEMP_C) }),
                    React.createElement(VerticalFormGroup, { formLabel: React.createElement(FormLabel, { labelText: 'ACH' }), input: editingCompany ? React.createElement(Input, { type: 'number', step: 0.1, disabled: !editingCompany, value: (_e = (editingCompany !== null && editingCompany !== void 0 ? editingCompany : company).estimate_default_ach) !== null && _e !== void 0 ? _e : '', validator: validateIsPositiveNumberOrZero, validateImmediately: true, setValue: ach => setEditingCompany(editingCompany && { ...editingCompany, estimate_default_ach: parseFloat(ach) }) }) : React.createElement("div", null, (_f = company === null || company === void 0 ? void 0 : company.estimate_default_ach) !== null && _f !== void 0 ? _f : DEFAULT_AIR_CHANGES) })),
                React.createElement(FlowTempSlider, { minFlowTemp: 35, maxFlowTemp: 55, flowTemp: (_g = (editingCompany !== null && editingCompany !== void 0 ? editingCompany : company).default_flow_temp_c) !== null && _g !== void 0 ? _g : DEFAULT_FLOW_TEMP_C, setFlowTemp: flowTemp => setEditingCompany(editingCompany && { ...editingCompany, default_flow_temp_c: flowTemp }) }),
                React.createElement("div", { className: 'flex gap-x-2 ' },
                    !editingCompany && React.createElement(React.Fragment, null,
                        React.createElement(Button, { colour: 'LIGHT', onClick: handleStartEditing }, "Edit")),
                    editingCompany &&
                        React.createElement(React.Fragment, null,
                            React.createElement(Button, { onClick: () => {
                                    handleUpdateCompany();
                                    handleStopEditingCompany();
                                }, disabled: !isValidForm }, "Save"),
                            React.createElement(Button, { colour: 'LIGHT', onClick: handleStopEditingCompany }, "Cancel"))))));
};
